import { CheckCircle, Circle, InfoRounded } from '@mui/icons-material';
import {
  Box,
  Button,
  Card,
  CardContent,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormLabel,
  Input,
  Link,
  Modal,
  ModalDialog,
  Sheet,
  Stack,
  Typography,
  useTheme,
} from '@mui/joy';
import { Elements } from '@stripe/react-stripe-js';
import { Stripe, loadStripe } from '@stripe/stripe-js';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { CreateSubscriptionResponseData, Product, User } from '@builder-bud/common';
import {
  AlertObject,
  api,
  showErrorAlert,
  useAppDispatch,
  useCreateSubscriptionMutation,
  useGetPaymentConfigQuery,
  useGetProductsQuery,
} from '@builder-bud/common-ui';

import CircularProgressComponent from '../../components/circular-progress.component';
import ErrorLoadingComponent from '../../components/error-loading.component';
import StripeForm from './stripe.form';

export default function SubscriptionsForm({ me }: { me: User }) {
  const dispatch = useAppDispatch();
  const theme = useTheme();

  const [stripePromise, setSetPromise] = useState<Promise<Stripe | null>>();
  const [searchParams, setSearchParams] = useSearchParams();

  const [createSubscription] = useCreateSubscriptionMutation();

  const [saving, setSaving] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState<Product | null>();
  const [promoCode, setPromoCode] = useState<string>('');
  const [clientSecret, setClientSecret] = useState<CreateSubscriptionResponseData | null>(null);
  const [modalOpen, setModalOpen] = useState(false);

  async function handleCreateSubscription() {
    setSaving(true);
    try {
      const response = await createSubscription({
        priceId: selectedProduct!.price.id,
        promoCode: promoCode === '' ? undefined : promoCode,
      }).unwrap();

      if (selectedProduct!.price.amount === 0) {
        setTimeout(() => {
          dispatch(api.util.invalidateTags(['me']));
          setSearchParams([]);
          setSaving(false);
        }, 2000);
      } else {
        setClientSecret(response);
        setSaving(false);
      }
    } catch (error) {
      console.log(error);
      dispatch(showErrorAlert(error as AlertObject));
      setSaving(false);
    }
  }

  const {
    data: products = [],
    isLoading: isProductsLoading,
    isFetching: isProductsFetching,
    isError: isProductsError,
    error: productsError,
  } = useGetProductsQuery();
  const {
    data: paymentConfig,
    isLoading: isConfigLoading,
    isFetching: isConfigFetching,
    isError: isConfigError,
    error: configError,
  } = useGetPaymentConfigQuery();

  useEffect(() => {
    if (paymentConfig?.stripePublishableKey) {
      setSetPromise(loadStripe(paymentConfig.stripePublishableKey));
    }
  }, [paymentConfig]);

  useEffect(() => {
    //When stripe redirects back wait for a few seconds to allow the stripe webhook to complete the subscription.
    if (searchParams.size > 0) {
      setSaving(true);
      setTimeout(() => {
        dispatch(api.util.invalidateTags(['me']));
        setSearchParams([]);
        setSaving(false);
      }, 2000);
    }
  }, [dispatch, searchParams, setSearchParams]);

  const isLoading = isProductsLoading || isConfigLoading;
  const isFetching = isProductsFetching || isConfigFetching;
  const isError = isProductsError || isConfigError;
  const error = isProductsError ? productsError : isConfigError ? configError : {};

  if (isFetching || isLoading || saving) return <CircularProgressComponent />;
  if (isError) return <ErrorLoadingComponent error={error} />;

  return (
    <Stack sx={{ display: 'flex', flex: 1, alignItems: 'center', maxWidth: 1200 }}>
      <Box sx={{ mb: 1 }}>
        <Typography level="h1">We've got a plan that's perfect for you</Typography>
      </Box>

      {!clientSecret ? (
        <>
          {me.stripeSubscriptionStatus === 'incomplete' && (
            <Stack sx={{ m: 1 }}>
              <Typography level="h4" color="warning">
                Your subscription is incomplete. Please contact support at{' '}
                <Link color="warning" href={'mailto:hello@builderbud.com'}>
                  hello@builderbud.com
                </Link>
                .
              </Typography>
            </Stack>
          )}
          <Stack sx={{ display: { xs: 'flex' }, my: 1, gap: 2, flexDirection: { xs: 'column', lg: 'row' } }}>
            {products.map((product) => {
              const activeProduct = me.stripeSubscriptionStatus === 'active' && me.stripeProductId === product.id;
              const iconColor = activeProduct ? 'warning' : 'primary';
              const circleIcon =
                activeProduct || selectedProduct === product ? <CheckCircle color={iconColor} /> : <Circle />;
              const fontColor = activeProduct ? 'warning' : selectedProduct === product ? 'primary' : 'neutral';
              const cost = (product.price.amount / 100).toFixed(2).split('.');

              return (
                <Card
                  variant={activeProduct ? 'solid' : 'plain'}
                  color={activeProduct ? 'primary' : 'neutral'}
                  key={product.id}
                  onClick={() => {
                    if (!activeProduct) {
                      setSelectedProduct(product);
                    }
                  }}
                >
                  <CardContent sx={{ gap: 3 }}>
                    <Box sx={{ mb: 1 }}>
                      <Typography level="h4" color={fontColor}>
                        {product.name}
                      </Typography>
                    </Box>
                    <Stack flexDirection="row">
                      <Typography level="h1" color={fontColor}>
                        ${cost[0]}
                      </Typography>
                      <Typography level="title-sm" color={fontColor} sx={{ marginBottom: 1, alignSelf: 'flex-end' }}>
                        .{cost[1]}
                      </Typography>
                      <Typography level="title-sm" color={fontColor} sx={{ margin: 1, alignSelf: 'flex-end' }}>
                        per {product.price.recurringInterval}
                      </Typography>
                    </Stack>
                    {activeProduct ? (
                      <Button size="sm" variant="soft" sx={{ flex: 1 }} color={iconColor} disabled>
                        Current Plan
                      </Button>
                    ) : selectedProduct === product ? (
                      <Button size="sm" variant="solid" sx={{ flex: 1 }} color={iconColor}>
                        Selected
                      </Button>
                    ) : (
                      <Button size="sm" variant="outlined" sx={{ flex: 1 }} color={iconColor}>
                        Select
                      </Button>
                    )}
                    <Stack sx={{ gap: 1 }}>
                      <Stack flexDirection="row" gap={1}>
                        {circleIcon}
                        <Typography level="title-md" color={fontColor}>
                          {product.maxProjects ? product.maxProjects : 'Unlimited'}{' '}
                          {product.maxProjects === 1 ? 'Project' : 'Projects'}
                        </Typography>
                      </Stack>
                      {/*<Stack flexDirection='row' gap={1}>{circleIcon}<Typography level="title-md" color={fontColor}>{product.maxUsers ? product.maxUsers : 'Unlimited'} Users</Typography></Stack>*/}
                      <Stack flexDirection="row" gap={1}>
                        {circleIcon}
                        <Typography level="title-md" color={fontColor}>
                          Chat by Project
                        </Typography>
                      </Stack>
                      <Stack flexDirection="row" gap={1}>
                        {circleIcon}
                        <Typography level="title-md" color={fontColor}>
                          Project Tasks & Punch list
                        </Typography>
                      </Stack>
                      <Stack flexDirection="row" gap={1}>
                        {circleIcon}
                        <Typography level="title-md" color={fontColor}>
                          Estimates, Invoicing and Progress Invoices
                        </Typography>
                      </Stack>
                    </Stack>
                  </CardContent>
                </Card>
              );
            })}
          </Stack>
          <FormControl>
            <FormLabel>Coupon code</FormLabel>
            <Input value={promoCode} onChange={(event) => setPromoCode(event.target.value)} />
          </FormControl>
          <Sheet
            sx={{
              display: 'flex',
              flexDirection: 'row',
              margin: 4,
              gap: 2,
              backgroundColor: theme.vars.palette.common.white,
            }}
          >
            {me.stripeSubscriptionStatus === 'active' && me.stripeProductId && (
              <Button
                size="lg"
                variant="outlined"
                sx={{ flex: 1 }}
                onClick={() => {
                  setModalOpen(true);
                }}
              >
                Cancel Subscription
              </Button>
            )}
            <Button size="lg" color="primary" onClick={handleCreateSubscription} disabled={!selectedProduct}>
              {me.stripeSubscriptionStatus === 'active' && me.stripeProductId ? 'Change Plan' : 'Select Plan'}
            </Button>
          </Sheet>
        </>
      ) : stripePromise ? (
        <Elements
          stripe={stripePromise}
          options={{
            clientSecret: clientSecret.paymentIntentClientSecret
              ? clientSecret.paymentIntentClientSecret
              : clientSecret.pendingSetupIntentClientSecret,
          }}
        >
          <StripeForm
            paymentIntentClientSecret={clientSecret.paymentIntentClientSecret}
            pendingSetupIntentClientSecret={clientSecret.pendingSetupIntentClientSecret}
            clearClientSecret={() => setClientSecret(null)}
          />
        </Elements>
      ) : (
        <Typography color="danger">Error loading payment form. Please contact support.</Typography>
      )}

      <Modal
        open={modalOpen}
        onClose={() => {
          setModalOpen(false);
        }}
      >
        <ModalDialog variant="outlined" role="alertdialog">
          <DialogTitle>
            <InfoRounded />
            Cancel subscription
          </DialogTitle>
          <Divider />
          <DialogContent>
            To cancel your subscription please send an email to{' '}
            <Link href={'mailto:hello@builderbud.com'}>hello@builderbud.com</Link>
          </DialogContent>
          <DialogActions>
            <Button variant="solid" component="a" href={'mailto:hello@builderbud.com'}>
              Email
            </Button>
            <Button variant="plain" color="neutral" onClick={() => setModalOpen(false)}>
              Cancel
            </Button>
          </DialogActions>
        </ModalDialog>
      </Modal>
    </Stack>
  );
}
