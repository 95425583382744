import { Add, ChevronRightRounded, HomeRounded } from '@mui/icons-material';
import {
  Box,
  Breadcrumbs,
  Dropdown,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  Tab,
  TabList,
  TabPanel,
  Tabs,
  Typography,
  tabClasses,
} from '@mui/joy';
import { matchPath, useLocation, useNavigate } from 'react-router-dom';

import { DailyLogId, DailyLogWithFiles, ProjectId, dateToDateWithoutTime } from '@builder-bud/common';
import {
  getUserOptions,
  isActiveProject,
  openModal,
  useAppDispatch,
  useEditDailyLogMutation,
  useGetDailyLogQuery,
  useGetProjectQuery,
  useGetUsersQuery,
} from '@builder-bud/common-ui';

import CircularProgressComponent from '../../components/circular-progress.component';
import DateComponent from '../../components/date.component';
import ErrorLoadingComponent from '../../components/error-loading.component';
import NotFoundComponent from '../../components/not-found.component';
import { useRequiredParams } from '../../utils';
import FilesTableComponent from '../files/files-table.component';
import ProjectFileUploadModal from '../files/project-file-upload.modal';
import DailyLogForm from './daily-log.form';

export default function DailyLogDetailsScreen() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const { projectId, dailyLogId } = useRequiredParams<{ projectId: ProjectId; dailyLogId: DailyLogId }>();

  const [updateDailyLog] = useEditDailyLogMutation();

  const { data: project } = useGetProjectQuery(projectId);

  const {
    data: dailyLog,
    isLoading: isDailyLogLoading,
    isFetching: isDailyLogFetching,
    isError: isDailyLogError,
    error: dailyLogError = {},
  } = useGetDailyLogQuery({ projectId, dailyLogId });

  const {
    data: users = [],
    isLoading: isUsersLoading,
    isFetching: isUsersFetching,
    isError: isUsersError,
    error: usersError = {},
  } = useGetUsersQuery();

  const isLoading = isDailyLogLoading || isUsersLoading;
  const isFetching = isDailyLogFetching || isUsersFetching;
  const isError = isDailyLogError || isUsersError;
  const error = isDailyLogError ? dailyLogError : isUsersError ? usersError : {};

  if (isLoading || isFetching) return <CircularProgressComponent />;
  if (isError) return <ErrorLoadingComponent error={error} />;
  if (!dailyLog) return <NotFoundComponent />;

  const modalContent = <ProjectFileUploadModal projectId={projectId} dailyLogId={dailyLogId} />;

  const getDefaultTabValue = () => {
    return matchPath('projects/:projectId/dailyLogs/:dailyLogId/files', location.pathname) ? 1 : 0;
  };

  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Breadcrumbs
          size="sm"
          aria-label="breadcrumbs"
          separator={<ChevronRightRounded fontSize="small" />}
          sx={{ pl: 0 }}
        >
          <Link underline="none" color="neutral" href="/" aria-label="Home">
            <HomeRounded />
          </Link>
          <Link underline="hover" color="neutral" href="/projects" fontSize={12} fontWeight={500}>
            Projects
          </Link>
          <Link
            underline="hover"
            color="neutral"
            href={`/projects/${dailyLog.projectId}`}
            fontSize={12}
            fontWeight={500}
          >
            {dailyLog.projectId}
          </Link>
          <Link
            underline="hover"
            color="neutral"
            href={`/projects/${dailyLog.projectId}/dailyLogs`}
            fontSize={12}
            fontWeight={500}
          >
            Daily Logs
          </Link>
          <Typography color="primary" fontWeight={500} fontSize={12}>
            {dailyLog.id}
          </Typography>
        </Breadcrumbs>
      </Box>
      <Box
        sx={{
          display: 'flex',
          mb: 1,
          gap: 1,
          flexDirection: { xs: 'column', sm: 'row' },
          alignItems: { xs: 'start', sm: 'center' },
          flexWrap: 'wrap',
          justifyContent: 'space-between',
        }}
      >
        <DateComponent date={dateToDateWithoutTime(dailyLog.logDate)} level="h2" />
        {isActiveProject(project) && (
          <Dropdown>
            <MenuButton color="secondary" startDecorator={<Add />}>
              Add to Daily Log
            </MenuButton>
            <Menu>
              <MenuItem
                color="secondary"
                onClick={() => {
                  dispatch(openModal(modalContent));
                }}
              >
                Add File
              </MenuItem>
            </Menu>
          </Dropdown>
        )}
      </Box>

      <Tabs
        defaultValue={0}
        value={getDefaultTabValue()}
        onChange={(e, selectedTab) => {
          selectedTab === 0 && navigate(`/projects/${projectId}/dailyLogs/${dailyLog.id}`, { replace: true });
          selectedTab === 1 && navigate(`/projects/${projectId}/dailyLogs/${dailyLog.id}/files`, { replace: true });
        }}
        sx={{
          bgcolor: 'transparent',
        }}
      >
        <TabList
          tabFlex={1}
          size="sm"
          sx={{
            pl: { xs: 0, md: 4 },
            justifyContent: 'left',
            [`&& .${tabClasses.root}`]: {
              fontWeight: '600',
              flex: 'initial',
              color: 'text.tertiary',
              [`&.${tabClasses.selected}`]: {
                bgcolor: 'transparent',
                color: 'text.primary',
                '&::after': {
                  height: '2px',
                  bgcolor: 'primary.500',
                },
              },
            },
          }}
        >
          <Tab sx={{ borderRadius: '6px 6px 0 0' }} indicatorInset value={0}>
            Details
          </Tab>
          <Tab sx={{ borderRadius: '6px 6px 0 0' }} indicatorInset value={1}>
            Files
          </Tab>
        </TabList>

        <TabPanel value={0}>
          <DailyLogForm
            dailyLog={dailyLog}
            saveDailyLog={updateDailyLog}
            users={getUserOptions(users, dailyLog.projectId)}
          />
        </TabPanel>
        <TabPanel value={1}>
          <FilesTableComponent rows={(dailyLog as DailyLogWithFiles).files} />
        </TabPanel>
      </Tabs>
    </>
  );
}
