/* eslint-disable jsx-a11y/anchor-is-valid */
import { ArrowDropDown, MoreHorizRounded, WarningRounded } from '@mui/icons-material';
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Dropdown,
  IconButton,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  Modal,
  ModalDialog,
  Typography,
} from '@mui/joy';
import { useState } from 'react';

import { ContractLineItem, ContractStatus, ContractType, ProjectId, UserProjectRole } from '@builder-bud/common';
import {
  AlertObject,
  getContractTotalAmount,
  getIsContract,
  getIsInvoice,
  getUserProjectRole,
  openModal,
  showErrorAlert,
  showInfoAlert,
  useAppDispatch,
  useAppSelector,
  useDeleteContractLineItemMutation,
  useEditContractLineItemMutation,
} from '@builder-bud/common-ui';

import CircularProgressComponent from '../../components/circular-progress.component';
import DateComponent from '../../components/date.component';
import { Order, getComparator } from '../../components/table';
import TableComponent from '../../components/table.component';
import ContractLineItemForm from './contract-line-item.form';

export default function ContractLineItemTableComponent({
  rows,
  projectId,
  contractType,
  contractStatus,
  homeownerView = false,
}: {
  rows: ContractLineItem[];
  projectId: ProjectId;
  contractType: ContractType;
  contractStatus: ContractStatus;
  homeownerView?: boolean;
}) {
  const dispatch = useAppDispatch();

  const [updateContractLineItem] = useEditContractLineItemMutation();
  const [deleteContractLineItem] = useDeleteContractLineItemMutation();

  const me = useAppSelector((state) => state.auth.me);
  const userProjectRole = getUserProjectRole(projectId, me?.projects);
  const showContractorFields = userProjectRole === UserProjectRole.Contractor && !homeownerView;

  const [order, setOrder] = useState<Order>('');
  const [modalOpen, setModalOpen] = useState(false);
  const [rowToDelete, setRowToDelete] = useState<ContractLineItem | null>(null);
  const [isDeleting, setIsDeleting] = useState(false);

  const isContract = getIsContract(contractType);
  const isInvoice = getIsInvoice(contractType);

  async function handleDeleteLineItem() {
    if (rowToDelete) {
      setIsDeleting(true);
      try {
        await deleteContractLineItem({
          projectId,
          contractId: rowToDelete.contractId,
          contractLineItemId: rowToDelete.id,
        }).unwrap();
        dispatch(showInfoAlert('Successfully deleted Work order'));
      } catch (error) {
        console.log(error);
        dispatch(showErrorAlert(error as AlertObject));
      } finally {
        setRowToDelete(null);
        setIsDeleting(false);
      }
    }
  }

  const renderFilters = () => <></>;

  const renderTableHead = () => (
    <thead>
      <tr>
        <th style={{ padding: '12px 6px' }}>
          <Link
            underline="none"
            color="primary"
            component="button"
            onClick={() => setOrder(order === '' ? 'asc' : order === 'asc' ? 'desc' : '')}
            fontWeight="lg"
            endDecorator={<ArrowDropDown />}
            sx={{
              '& svg': {
                transition: '0.2s',
                transform: order === '' ? 'rotate(90deg)' : order === 'desc' ? 'rotate(0deg)' : 'rotate(180deg)',
              },
            }}
          >
            Name
          </Link>
        </th>
        <th style={{ padding: '12px 6px' }}>Description</th>
        {isContract && <th style={{ padding: '12px 6px' }}>Quantity</th>}
        {showContractorFields && isContract && <th style={{ padding: '12px 6px' }}>Cost</th>}
        {showContractorFields && isContract && <th style={{ padding: '12px 6px' }}>Profit Percentage</th>}
        {isContract && <th style={{ padding: '12px 6px' }}>Customer Rate</th>}
        {isContract && <th style={{ padding: '12px 6px' }}>Amount</th>}
        {isInvoice && <th style={{ padding: '12px 6px' }}>Date</th>}
        {isInvoice && <th style={{ padding: '12px 6px' }}>Amount</th>}
        {showContractorFields && contractStatus === ContractStatus.Draft && (
          <th style={{ width: 48, padding: '12px 6px' }}></th>
        )}
      </tr>
    </thead>
  );

  function TableRowMenu({ row }: { row: ContractLineItem }) {
    return (
      <Dropdown>
        <MenuButton
          slots={{ root: IconButton }}
          slotProps={{ root: { variant: 'plain', color: 'neutral', size: 'sm' } }}
        >
          <MoreHorizRounded />
        </MenuButton>
        <Menu size="sm" sx={{ minWidth: 140 }}>
          <MenuItem
            onClick={() => {
              const contractLineItemModalContent = (
                <ContractLineItemForm
                  contractLineItem={row}
                  projectId={projectId}
                  contractType={contractType}
                  saveContractLineItem={updateContractLineItem}
                />
              );
              dispatch(openModal(contractLineItemModalContent));
            }}
          >
            Edit
          </MenuItem>
          <Divider />
          <MenuItem
            color="danger"
            onClick={() => {
              setRowToDelete(row);
              setModalOpen(true);
            }}
          >
            Delete
          </MenuItem>
        </Menu>
      </Dropdown>
    );
  }

  function TableRow({ row }: { row: ContractLineItem }) {
    return (
      <tr key={row.id} style={{ backgroundColor: 'var(--joy-palette-common-white)' }}>
        <td>{row.name}</td>
        <td>{row.description}</td>
        {isContract && <td>{row.quantity}</td>}
        {showContractorFields && isContract && <td>${row.cost}</td>}
        {showContractorFields && isContract && <td>{row.margin ? Math.ceil((row.margin - 1) * 100) : 0}%</td>}
        {isContract && <td>${row.calculatedRate}</td>}
        {isContract && <td>${row.calculatedAmount}</td>}
        {isInvoice && (
          <td>
            <DateComponent date={row.date} />
          </td>
        )}
        {isInvoice && <td>${row.amount}</td>}
        {showContractorFields && contractStatus === ContractStatus.Draft && (
          <td>
            <TableRowMenu row={row} />
          </td>
        )}
      </tr>
    );
  }

  if (isDeleting) return <CircularProgressComponent />;

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <TableComponent
        renderFilters={renderFilters}
        renderTableHead={renderTableHead}
        TableRow={TableRow}
        rows={rows}
        comparator={getComparator(order, 'name')}
        hideSearch={true}
        hideEmptyList={true}
      />
      <Box sx={{ alignSelf: 'flex-end', margin: 2 }}>
        <Typography level="title-lg">Total Amount: ${getContractTotalAmount(rows, contractType)}</Typography>
      </Box>
      <Modal
        open={modalOpen}
        onClose={() => {
          setRowToDelete(null);
          setModalOpen(false);
        }}
      >
        <ModalDialog variant="outlined" role="alertdialog">
          <DialogTitle>
            <WarningRounded />
            Confirmation
          </DialogTitle>
          <Divider />
          <DialogContent>Are you sure you want to delete this line item?</DialogContent>
          <DialogActions>
            <Button variant="solid" color="danger" onClick={() => handleDeleteLineItem()}>
              Delete line item
            </Button>
            <Button variant="plain" color="neutral" onClick={() => setModalOpen(false)}>
              Cancel
            </Button>
          </DialogActions>
        </ModalDialog>
      </Modal>
    </Box>
  );
}
