import { Description } from '@mui/icons-material';
import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardOverflow,
  Divider,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  Stack,
  Textarea,
  Typography,
} from '@mui/joy';
import { ReactElement, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { File, FileType, User } from '@builder-bud/common';
import {
  AlertObject,
  UserFormData,
  UserSchemaResolver,
  getDefaultUserFormValues,
  getUserSubmitData,
  showErrorAlert,
  showInfoAlert,
  useAppDispatch,
  useUpdateMeMutation,
} from '@builder-bud/common-ui';

import ProfilePhotoUploadComponent from '../files/profile-photo-upload.component';

export default function ProfileForm({ me, files }: { me: User; files: File[] }) {
  const dispatch = useAppDispatch();

  const [updateUser] = useUpdateMeMutation();
  const [saving, setSaving] = useState(false);

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors, isDirty },
  } = useForm<UserFormData>({
    defaultValues: getDefaultUserFormValues(me),
    resolver: UserSchemaResolver,
  });

  async function onSubmit(data: UserFormData) {
    setSaving(true);
    try {
      const userData = getUserSubmitData(data);
      await updateUser(userData).unwrap();
      dispatch(showInfoAlert('Successfully updated profile'));
    } catch (error) {
      console.log(error);
      dispatch(showErrorAlert(error as AlertObject));
    } finally {
      setSaving(false);
    }
  }

  const initials = me ? me.firstName.slice(0, 1) + me.lastName.slice(0, 1) : '';
  const avatarPlaceHolder: ReactElement = (
    <Avatar color="primary" sx={{ height: 200, width: 200 }}>
      {initials}
    </Avatar>
  );
  const cardPlaceHolder: ReactElement = (
    <CardContent style={{ justifyContent: 'center', alignItems: 'center' }}>
      <Description style={{ fontSize: 120 }} />
    </CardContent>
  );

  return (
    <Card sx={{ display: 'flex', flex: 1, maxWidth: 800 }}>
      <Box sx={{ mb: 1 }}>
        <Typography level="title-md">Profile management</Typography>
      </Box>
      <Divider />

      <Stack direction="row" spacing={3} sx={{ display: { xs: 'flex' }, my: 1 }}>
        <Stack spacing={1}>
          <ProfilePhotoUploadComponent placeHolder={avatarPlaceHolder} fileType={FileType.ProfileImage} files={files} />
        </Stack>
        <Stack spacing={2} sx={{ flexGrow: 1 }}>
          <Controller
            control={control}
            name="firstName"
            render={({ field: { onChange, onBlur, value: controlValue } }) => (
              <FormControl error={!!errors.firstName} required>
                <FormLabel>First Name</FormLabel>
                <Input value={controlValue} onBlur={onBlur} onChange={onChange} />
                {errors.firstName && <FormHelperText>{errors.firstName.message}</FormHelperText>}
              </FormControl>
            )}
          />

          <Controller
            control={control}
            name="lastName"
            render={({ field: { onChange, onBlur, value: controlValue } }) => (
              <FormControl error={!!errors.lastName} required>
                <FormLabel>Last Name</FormLabel>
                <Input value={controlValue} onBlur={onBlur} onChange={onChange} />
                {errors.lastName && <FormHelperText>{errors.lastName.message}</FormHelperText>}
              </FormControl>
            )}
          />
        </Stack>
      </Stack>

      <Stack spacing={3} sx={{ display: { xs: 'flex' }, my: 1 }}>
        <Controller
          control={control}
          name="businessName"
          render={({ field: { onChange, onBlur, value: controlValue } }) => (
            <FormControl error={!!errors.businessName}>
              <FormLabel>Business Name</FormLabel>
              <Input value={controlValue} onBlur={onBlur} onChange={onChange} />
              {errors.businessName && <FormHelperText>{errors.businessName.message}</FormHelperText>}
            </FormControl>
          )}
        />

        <Controller
          control={control}
          name="displayName"
          render={({ field: { onChange, onBlur, value: controlValue } }) => (
            <FormControl error={!!errors.displayName}>
              <FormLabel>Display Name</FormLabel>
              <Input value={controlValue} onBlur={onBlur} onChange={onChange} />
              {errors.displayName && <FormHelperText>{errors.displayName.message}</FormHelperText>}
            </FormControl>
          )}
        />

        <Controller
          control={control}
          name="bio"
          render={({ field: { onChange, onBlur, value: controlValue } }) => (
            <FormControl error={!!errors.bio}>
              <FormLabel>Bio</FormLabel>
              <Textarea value={controlValue} onBlur={onBlur} onChange={onChange} minRows={2} />
              {errors.bio && <FormHelperText>{errors.bio.message}</FormHelperText>}
            </FormControl>
          )}
        />

        <Controller
          control={control}
          name="license"
          render={({ field: { onChange, onBlur, value: controlValue } }) => (
            <FormControl error={!!errors.license}>
              <FormLabel>License #</FormLabel>
              <Input value={controlValue} onBlur={onBlur} onChange={onChange} />
              {errors.license && <FormHelperText>{errors.license.message}</FormHelperText>}
            </FormControl>
          )}
        />

        <Controller
          control={control}
          name="website"
          render={({ field: { onChange, onBlur, value: controlValue } }) => (
            <FormControl error={!!errors.website}>
              <FormLabel>Website</FormLabel>
              <Input value={controlValue} onBlur={onBlur} onChange={onChange} />
              {errors.website && <FormHelperText>{errors.website.message}</FormHelperText>}
            </FormControl>
          )}
        />
      </Stack>

      <Stack spacing={3}>
        <Typography level="title-sm">License</Typography>
        <ProfilePhotoUploadComponent placeHolder={cardPlaceHolder} fileType={FileType.License} files={files} />
        <Typography level="title-sm">Certificate of Insurance (COI)</Typography>
        <ProfilePhotoUploadComponent placeHolder={cardPlaceHolder} fileType={FileType.COI} files={files} />
      </Stack>

      <CardOverflow sx={{ borderTop: '1px solid', borderColor: 'divider' }}>
        <CardActions sx={{ alignSelf: 'flex-end', pt: 2 }}>
          <Button size="sm" variant="outlined" onClick={() => reset()} disabled={!isDirty || saving}>
            Cancel
          </Button>
          <Button size="sm" variant="solid" onClick={handleSubmit(onSubmit)} disabled={saving}>
            Save
          </Button>
        </CardActions>
      </CardOverflow>
    </Card>
  );
}
