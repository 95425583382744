import {
  Box,
  Button,
  Card,
  CardActions,
  CardOverflow,
  Divider,
  FormControl,
  FormHelperText,
  FormLabel,
  Grid,
  Input,
  Option,
  Select,
  Stack,
  Textarea,
  Typography,
} from '@mui/joy';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { matchPath, useLocation, useNavigate } from 'react-router-dom';

import { Task, canUserAssignTask, canUserUpdateTask } from '@builder-bud/common';
import {
  AlertObject,
  TASK_STATUS_OPTIONS,
  TaskFormData,
  TaskSchemaResolver,
  UserOption,
  getDefaultTaskFormValues,
  getTaskSubmitData,
  showErrorAlert,
  showInfoAlert,
  useAppDispatch,
  useAppSelector,
} from '@builder-bud/common-ui';

import { DatePickerComponent } from '../../components/date-picker.component';

export default function TaskForm({
  task,
  saveTask,
  users,
}: {
  task: Partial<Task>;
  saveTask: any;
  users: UserOption[];
}) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const me = useAppSelector((state) => state.auth.me);
  const isAllowedUpdateTask = !task.id || (me && canUserUpdateTask(task as Task, me.id));
  const isAllowedAssignTask = !task.id || (me && canUserAssignTask(task as Task, me.id));

  const [saving, setSaving] = useState(false);

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors, isDirty },
  } = useForm<TaskFormData>({
    defaultValues: getDefaultTaskFormValues(users, task),
    resolver: TaskSchemaResolver,
  });

  async function onSubmit(data: TaskFormData) {
    setSaving(true);
    try {
      const taskData = getTaskSubmitData(data);
      if (!isAllowedAssignTask) {
        delete taskData.assignedToUserIds;
      }
      const response = await saveTask({ ...taskData, ...{ id: task.id, projectId: task.projectId } }).unwrap();
      dispatch(showInfoAlert(task.id ? 'Successfully updated task' : 'Successfully created task'));
      if (matchPath(`/projects/${response.projectId}/tasks/new`, location.pathname)) {
        navigate(`/projects/${response.projectId}/tasks/${response.id}`, { replace: true });
      }
    } catch (error) {
      console.log(error);
      dispatch(showErrorAlert(error as AlertObject));
    } finally {
      setSaving(false);
    }
  }

  return (
    <Card style={{ backgroundColor: 'var(--joy-palette-common-white)' }}>
      <Box sx={{ mb: 1 }}>
        <Typography level="title-md">Task info</Typography>
      </Box>
      <Divider />
      <Stack direction="row" spacing={3} sx={{ display: { xs: 'flex' }, my: 1 }}>
        <Stack spacing={2} sx={{ flexGrow: 1 }}>
          <Grid container spacing={2}>
            <Grid xs={12} md={6}>
              <Controller
                control={control}
                name="name"
                render={({ field: { onChange, onBlur, value: controlValue } }) => (
                  <FormControl error={!!errors.name} required>
                    <FormLabel>Task name</FormLabel>
                    <Input value={controlValue} onBlur={onBlur} onChange={onChange} disabled={!isAllowedUpdateTask} />
                    {errors.name && <FormHelperText>{errors.name.message}</FormHelperText>}
                  </FormControl>
                )}
              />
            </Grid>
            <Grid xs={12} md={6}>
              <Controller
                control={control}
                name="status"
                render={({ field: { onChange, onBlur, value: controlValue } }) => (
                  <FormControl error={!!errors.status}>
                    <FormLabel>Status</FormLabel>
                    <Select
                      value={controlValue}
                      onChange={(e, newValue) => {
                        onChange(newValue);
                      }}
                      onBlur={onBlur}
                      disabled={!isAllowedUpdateTask}
                    >
                      {TASK_STATUS_OPTIONS.map((option, i) => (
                        <Option key={option.value} value={option.value}>
                          {option.label}
                        </Option>
                      ))}
                    </Select>
                    {errors.status && <FormHelperText>{errors.status.message}</FormHelperText>}
                  </FormControl>
                )}
              />
            </Grid>
            <Grid xs={12} md={6}>
              <Controller
                control={control}
                name="assignedToUserIds"
                render={({ field: { onChange, onBlur, value: controlValue } }) => (
                  <FormControl error={!!errors.assignedToUserIds}>
                    <FormLabel>Assigned To</FormLabel>
                    <Select
                      value={controlValue}
                      onChange={(e, newValue) => {
                        onChange(newValue);
                      }}
                      onBlur={onBlur}
                      multiple={true}
                      disabled={!isAllowedAssignTask}
                    >
                      {users.map((option, i) => (
                        <Option key={option.value} value={option.value}>
                          {option.label}
                        </Option>
                      ))}
                    </Select>
                    {errors.assignedToUserIds && <FormHelperText>{errors.assignedToUserIds.message}</FormHelperText>}
                  </FormControl>
                )}
              />
            </Grid>
            <Grid xs={12} md={6}>
              <Controller
                control={control}
                name="dueDate"
                render={({ field: { onChange, onBlur, value: controlValue } }) => (
                  <FormControl error={!!errors.dueDate}>
                    <FormLabel>Due Date</FormLabel>
                    <DatePickerComponent value={controlValue} onChange={onChange} disabled={!isAllowedUpdateTask} />
                    {errors.dueDate && <FormHelperText>{errors.dueDate.message}</FormHelperText>}
                  </FormControl>
                )}
              />
            </Grid>
            <Grid xs={12}>
              <Controller
                control={control}
                name="description"
                render={({ field: { onChange, onBlur, value: controlValue } }) => (
                  <FormControl error={!!errors.description}>
                    <FormLabel>Description</FormLabel>
                    <Textarea
                      value={controlValue}
                      onBlur={onBlur}
                      onChange={onChange}
                      minRows={2}
                      disabled={!isAllowedUpdateTask}
                    />
                    {errors.description && <FormHelperText>{errors.description.message}</FormHelperText>}
                  </FormControl>
                )}
              />
            </Grid>
            <Grid xs={12}>
              <Controller
                control={control}
                name="notes"
                render={({ field: { onChange, onBlur, value: controlValue } }) => (
                  <FormControl error={!!errors.notes}>
                    <FormLabel>Notes</FormLabel>
                    <Textarea
                      value={controlValue}
                      onBlur={onBlur}
                      onChange={onChange}
                      minRows={4}
                      disabled={!isAllowedUpdateTask}
                    />
                    {errors.notes && <FormHelperText>{errors.notes.message}</FormHelperText>}
                  </FormControl>
                )}
              />
            </Grid>
          </Grid>
        </Stack>
      </Stack>
      {isAllowedUpdateTask && (
        <CardOverflow sx={{ borderTop: '1px solid', borderColor: 'divider' }}>
          <CardActions sx={{ alignSelf: 'flex-end', pt: 2 }}>
            <Button
              size="sm"
              variant="outlined"
              onClick={() => (task.id ? reset() : navigate(-1))}
              disabled={!isDirty && !!task.id}
            >
              Cancel
            </Button>
            <Button size="sm" variant="solid" onClick={handleSubmit(onSubmit)} disabled={!isDirty || saving}>
              Save
            </Button>
          </CardActions>
        </CardOverflow>
      )}
    </Card>
  );
}
