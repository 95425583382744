/* eslint-disable jsx-a11y/anchor-is-valid */
import { ArrowDropDown, MoreHorizRounded, WarningRounded } from '@mui/icons-material';
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Dropdown,
  FormControl,
  FormLabel,
  IconButton,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  Modal,
  ModalDialog,
  Option,
  Select,
  Typography,
} from '@mui/joy';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Contract, ContractStatus, UserProjectRole } from '@builder-bud/common';
import {
  AlertObject,
  CONTRACT_STATUS_OPTIONS,
  getContractTotalAmount,
  getContractTypeLabel,
  getIsInvoice,
  getUserProjectRole,
  showErrorAlert,
  showInfoAlert,
  useAppDispatch,
  useAppSelector,
  useDeleteContractMutation,
} from '@builder-bud/common-ui';

import CircularProgressComponent from '../../components/circular-progress.component';
import ContractStatusComponent from '../../components/contract-status.component';
import DateComponent from '../../components/date.component';
import MemberComponent from '../../components/member.component';
import ProjectNameComponent from '../../components/project-name';
import { Order, getComparator } from '../../components/table';
import TableComponent from '../../components/table.component';

export default function ContractTableComponent({
  rows,
  showProject = false,
  hideSearch = false,
}: {
  rows: Contract[];
  showProject?: boolean;
  hideSearch?: boolean;
}) {
  const dispatch = useAppDispatch();
  const [deleteContract] = useDeleteContractMutation();

  const me = useAppSelector((state) => state.auth.me);

  const [order, setOrder] = useState<Order>('');
  const [statusFilter, setStatusFilter] = useState<string[]>([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [rowToDelete, setRowToDelete] = useState<Contract | null>(null);
  const [isDeleting, setIsDeleting] = useState(false);

  const filteredRows = statusFilter.length ? rows.filter((row) => statusFilter.includes(row.status)) : rows;

  async function handleDeleteContract() {
    if (rowToDelete) {
      setIsDeleting(true);
      try {
        await deleteContract({ projectId: rowToDelete.projectId, id: rowToDelete.id }).unwrap();
        dispatch(showInfoAlert('Successfully deleted Work order'));
      } catch (error) {
        console.log(error);
        dispatch(showErrorAlert(error as AlertObject));
      } finally {
        setRowToDelete(null);
        setIsDeleting(false);
      }
    }
  }

  const renderFilters = () => (
    <FormControl size="sm">
      <FormLabel>Status</FormLabel>
      <Select
        value={statusFilter}
        onChange={(e, newValue) => setStatusFilter(newValue)}
        size="sm"
        placeholder="Filter by status"
        slotProps={{ button: { sx: { whiteSpace: 'nowrap' } } }}
        multiple={true}
      >
        {CONTRACT_STATUS_OPTIONS.map((option) => (
          <Option key={option.value} value={option.value}>
            {option.label}
          </Option>
        ))}
      </Select>
    </FormControl>
  );

  const renderTableHead = () => (
    <thead>
      <tr>
        <th style={{ padding: '12px 6px' }}>
          <Link
            underline="none"
            color="primary"
            component="button"
            onClick={() => setOrder(order === '' ? 'asc' : order === 'asc' ? 'desc' : '')}
            fontWeight="lg"
            endDecorator={<ArrowDropDown />}
            sx={{
              '& svg': {
                transition: '0.2s',
                transform: order === '' ? 'rotate(90deg)' : order === 'desc' ? 'rotate(0deg)' : 'rotate(180deg)',
              },
            }}
          >
            Work order
          </Link>
        </th>
        {showProject && <th style={{ padding: '12px 6px' }}>Project name</th>}
        <th style={{ padding: '12px 6px' }}>Type</th>
        <th style={{ padding: '12px 6px' }}>Amount</th>
        <th style={{ padding: '12px 6px' }}>Status</th>
        <th style={{ padding: '12px 6px' }}>Signature</th>
        <th style={{ padding: '12px 6px' }}>Invoice Date</th>
        <th style={{ padding: '12px 6px' }}>Due Date</th>
        <th style={{ padding: '12px 6px' }}>Created By</th>
        <th style={{ width: 48, padding: '12px 6px' }}></th>
      </tr>
    </thead>
  );

  function TableRowMenu({ row }: { row: Contract }) {
    const navigate = useNavigate();
    const userProjectRole = getUserProjectRole(row.projectId, me?.projects);

    return (
      <Dropdown>
        <MenuButton
          slots={{ root: IconButton }}
          slotProps={{ root: { variant: 'plain', color: 'neutral', size: 'sm' } }}
        >
          <MoreHorizRounded />
        </MenuButton>
        <Menu size="sm" sx={{ minWidth: 140 }}>
          <MenuItem onClick={() => navigate(`/projects/${row.projectId}/contracts/${row.id}`)}>View</MenuItem>
          {userProjectRole === UserProjectRole.Contractor && row.status !== ContractStatus.Approved && (
            <>
              <Divider />
              <MenuItem
                color="danger"
                onClick={() => {
                  setRowToDelete(row);
                  setModalOpen(true);
                }}
              >
                Delete
              </MenuItem>
            </>
          )}
        </Menu>
      </Dropdown>
    );
  }

  function TableRow({ row }: { row: Contract }) {
    const navigate = useNavigate();

    const isInvoice = getIsInvoice(row.type);

    return (
      <tr key={row.id} style={{ backgroundColor: 'var(--joy-palette-common-white)' }}>
        <td>
          <Link onClick={() => navigate(`/projects/${row.projectId}/contracts/${row.id}`)}>
            <Typography level="body-xs">{row.name}</Typography>
          </Link>
        </td>

        {showProject && (
          <td>
            <Typography>
              <ProjectNameComponent projectId={row.projectId} />
            </Typography>
          </td>
        )}
        <td>{getContractTypeLabel(row.type)}</td>
        <td>${getContractTotalAmount(row.lineItems ? row.lineItems : [], row.type)}</td>
        <td>
          <ContractStatusComponent contract={row} />
        </td>
        <td>
          <Typography>{row.signatureRequired ? 'Yes' : 'No'}</Typography>
        </td>
        <td>{isInvoice && <DateComponent date={row.invoiceDate} />}</td>
        <td>
          <DateComponent date={row.dueDate} />
        </td>
        <td>
          <MemberComponent userId={row.createdByUserId} />
        </td>
        <td>
          <TableRowMenu row={row} />
        </td>
      </tr>
    );
  }

  if (isDeleting) return <CircularProgressComponent />;

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <TableComponent
        renderFilters={renderFilters}
        renderTableHead={renderTableHead}
        TableRow={TableRow}
        rows={filteredRows}
        comparator={getComparator(order, 'name')}
        hideSearch={hideSearch}
      />

      <Modal
        open={modalOpen}
        onClose={() => {
          setRowToDelete(null);
          setModalOpen(false);
        }}
      >
        <ModalDialog variant="outlined" role="alertdialog">
          <DialogTitle>
            <WarningRounded />
            Confirmation
          </DialogTitle>
          <Divider />
          <DialogContent>Are you sure you want to delete this contract?</DialogContent>
          <DialogActions>
            <Button variant="solid" color="danger" onClick={() => handleDeleteContract()}>
              Delete contract
            </Button>
            <Button variant="plain" color="neutral" onClick={() => setModalOpen(false)}>
              Cancel
            </Button>
          </DialogActions>
        </ModalDialog>
      </Modal>
    </Box>
  );
}
