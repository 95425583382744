import { Box, Typography } from '@mui/joy';

function EmptyListComponent({ description = null }: { description?: string | null }) {
  return (
    <Box sx={styles.container}>
      <Typography level="h3">{description ? description : 'No items found'}</Typography>
      <img src={'../../assets/line-4-tools-row.png'} style={styles.image} alt="" />
    </Box>
  );
}

export default EmptyListComponent;

const styles = {
  container: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  image: {
    height: 480,
  },
};
