import { AttachMoney, Percent } from '@mui/icons-material';
import {
  Button,
  Card,
  CardActions,
  CardOverflow,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  Textarea,
} from '@mui/joy';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { ContractLineItem, ContractType, ProjectId, UserProjectRole } from '@builder-bud/common';
import {
  AlertObject,
  LineItemFormData,
  LineItemSchemaResolver,
  closeModal,
  getDefaultLineItemFormValues,
  getIsContract,
  getIsInvoice,
  getLineItemSubmitData,
  getUserProjectRole,
  showErrorAlert,
  showInfoAlert,
  useAppDispatch,
  useAppSelector,
} from '@builder-bud/common-ui';

import CircularProgressComponent from '../../components/circular-progress.component';
import { DatePickerComponent } from '../../components/date-picker.component';

export default function ContractLineItemForm({
  contractLineItem,
  projectId,
  contractType,
  saveContractLineItem,
}: {
  contractLineItem: Partial<ContractLineItem>;
  projectId: ProjectId;
  contractType: ContractType;
  saveContractLineItem: any;
}) {
  const dispatch = useAppDispatch();

  const me = useAppSelector((state) => state.auth.me);
  const userProjectRole = getUserProjectRole(projectId, me?.projects);

  const [saving, setSaving] = useState(false);

  const {
    control,
    handleSubmit,
    watch,
    formState: { errors, isDirty },
  } = useForm<LineItemFormData>({
    defaultValues: getDefaultLineItemFormValues(contractLineItem),
    resolver: LineItemSchemaResolver,
  });

  async function onSubmit(data: LineItemFormData) {
    setSaving(true);
    try {
      const contractLineItemData = getLineItemSubmitData(data);
      const response = await saveContractLineItem({
        ...contractLineItemData,
        ...{ contractLineItemId: contractLineItem.id, contractId: contractLineItem.contractId, projectId: projectId },
      }).unwrap();

      if (!contractLineItem.id) {
        contractLineItem.id = response.id;
      }

      dispatch(closeModal());
      dispatch(
        showInfoAlert(
          contractLineItem.id ? 'Successfully updated contract line item' : 'Successfully created contract line item'
        )
      );
    } catch (error) {
      console.log(error);
      dispatch(showErrorAlert(error as AlertObject));
    } finally {
      setSaving(false);
    }
  }

  if (saving) return <CircularProgressComponent size="sm" />;

  const isContract = getIsContract(contractType);
  const isInvoice = getIsInvoice(contractType);

  const quantity = watch('quantity');
  const cost = watch('cost');
  const margin = watch('margin');

  return (
    <Card style={{ backgroundColor: 'var(--joy-palette-common-white)' }}>
      <table>
        <tr>
          <td>
            <Controller
              control={control}
              name="name"
              render={({ field: { onChange, onBlur, value: controlValue } }) => (
                <FormControl error={!!errors.name} required>
                  <FormLabel>Name</FormLabel>
                  <Input value={controlValue} onBlur={onBlur} onChange={onChange} />
                  {errors.name && <FormHelperText>{errors.name.message}</FormHelperText>}
                </FormControl>
              )}
            />
          </td>

          <td>
            <Controller
              control={control}
              name="description"
              render={({ field: { onChange, onBlur, value: controlValue } }) => (
                <FormControl error={!!errors.description}>
                  <FormLabel>Description</FormLabel>
                  <Textarea value={controlValue} onBlur={onBlur} onChange={onChange} />
                  {errors.description && <FormHelperText>{errors.description.message}</FormHelperText>}
                </FormControl>
              )}
            />
          </td>

          {isContract && (
            <td>
              <Controller
                control={control}
                name="quantity"
                render={({ field: { onChange, onBlur, value: controlValue } }) => (
                  <FormControl error={!!errors.quantity}>
                    <FormLabel>Quantity</FormLabel>
                    <Input value={controlValue} onBlur={onBlur} onChange={onChange} />
                    {errors.quantity && <FormHelperText>{errors.quantity.message}</FormHelperText>}
                  </FormControl>
                )}
              />
            </td>
          )}

          {userProjectRole === UserProjectRole.Contractor && isContract && (
            <>
              <td>
                <Controller
                  control={control}
                  name="cost"
                  render={({ field: { onChange, onBlur, value: controlValue } }) => (
                    <FormControl error={!!errors.cost}>
                      <FormLabel>Cost</FormLabel>
                      <Input
                        value={controlValue}
                        onBlur={onBlur}
                        onChange={onChange}
                        startDecorator={<AttachMoney />}
                      />
                      {errors.cost && <FormHelperText>{errors.cost.message}</FormHelperText>}
                    </FormControl>
                  )}
                />
              </td>
              <td>
                <Controller
                  control={control}
                  name="margin"
                  render={({ field: { onChange, onBlur, value: controlValue } }) => (
                    <FormControl error={!!errors.quantity}>
                      <FormLabel>Profit Percentage</FormLabel>
                      <Input value={controlValue} onBlur={onBlur} onChange={onChange} endDecorator={<Percent />} />
                      {errors.margin && <FormHelperText>{errors.margin.message}</FormHelperText>}
                    </FormControl>
                  )}
                />
              </td>
            </>
          )}

          {isContract && (
            <>
              <td>
                <Controller
                  control={control}
                  name="calculatedRate"
                  render={({ field: { onChange, onBlur, value: controlValue } }) => (
                    <FormControl error={!!errors.calculatedRate}>
                      <FormLabel>Customer Rate</FormLabel>
                      <Input value={cost! * (margin! / 100 + 1)} startDecorator={<AttachMoney />} readOnly />
                    </FormControl>
                  )}
                />
              </td>
              <td>
                <FormControl error={!!errors.calculatedAmount}>
                  <FormLabel>Amount</FormLabel>
                  <Input value={quantity * (cost! * (margin! / 100 + 1))} startDecorator={<AttachMoney />} />
                </FormControl>
              </td>
            </>
          )}

          {isInvoice && (
            <>
              <td>
                <Controller
                  control={control}
                  name="date"
                  render={({ field: { onChange, onBlur, value: controlValue } }) => (
                    <FormControl error={!!errors.date}>
                      <FormLabel>Date</FormLabel>
                      <DatePickerComponent value={controlValue} onChange={onChange} />
                      {errors.date && <FormHelperText>{errors.date.message}</FormHelperText>}
                    </FormControl>
                  )}
                />
              </td>
              <td>
                <Controller
                  control={control}
                  name="amount"
                  render={({ field: { onChange, onBlur, value: controlValue } }) => (
                    <FormControl error={!!errors.amount}>
                      <FormLabel>Amount</FormLabel>
                      <Input
                        value={controlValue}
                        onBlur={onBlur}
                        onChange={onChange}
                        startDecorator={<AttachMoney />}
                      />
                    </FormControl>
                  )}
                />
              </td>
            </>
          )}
        </tr>
      </table>

      <CardOverflow sx={{ borderTop: '1px solid', borderColor: 'divider' }}>
        <CardActions sx={{ alignSelf: 'flex-end', pt: 2 }}>
          <Button size="sm" variant="outlined" onClick={() => dispatch(closeModal())}>
            Cancel
          </Button>
          <Button size="sm" variant="solid" onClick={handleSubmit(onSubmit)} disabled={!isDirty || saving}>
            Save
          </Button>
        </CardActions>
      </CardOverflow>
    </Card>
  );
}
