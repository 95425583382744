import {
  Box,
  Button,
  Card,
  CardActions,
  CardOverflow,
  Divider,
  FormControl,
  FormHelperText,
  FormLabel,
  Grid,
  Option,
  Select,
  Stack,
  Textarea,
  Typography,
} from '@mui/joy';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { matchPath, useLocation, useNavigate } from 'react-router-dom';

import { DailyLog, DailyLogType, canUserCreateDailyLog, canUserUpdateDailyLog } from '@builder-bud/common';
import {
  AlertObject,
  DAILY_LOG_OPTIONS,
  DailyLogFormData,
  DailyLogSchemaResolver,
  UserOption,
  getDailyLogSubmitData,
  getDefaultDailyLogFormValues,
  getUserProjectRole,
  showErrorAlert,
  showInfoAlert,
  useAppDispatch,
  useAppSelector,
} from '@builder-bud/common-ui';

import { DatePickerComponent } from '../../components/date-picker.component';

export default function DailyLogForm({
  dailyLog,
  saveDailyLog,
  users,
}: {
  dailyLog: Partial<DailyLog>;
  saveDailyLog: any;
  users: UserOption[];
}) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [saving, setSaving] = useState(false);

  const me = useAppSelector((state) => state.auth.me);
  const userProjectRole = getUserProjectRole(dailyLog.projectId, me?.projects);
  const isAllowedUpdateDailyLog =
    !dailyLog.id || (me && userProjectRole && canUserUpdateDailyLog(dailyLog as DailyLog, userProjectRole, me.id));

  const dailyLogOptions = DAILY_LOG_OPTIONS.filter(
    (dailyLogType) => userProjectRole && canUserCreateDailyLog(userProjectRole, dailyLogType.value as DailyLogType)
  );

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors, isDirty },
  } = useForm<DailyLogFormData>({
    defaultValues: getDefaultDailyLogFormValues(dailyLog),
    resolver: DailyLogSchemaResolver,
  });

  async function onSubmit(data: DailyLogFormData) {
    setSaving(true);
    try {
      const dailyLogData = getDailyLogSubmitData(data);
      const response = await saveDailyLog({
        ...dailyLogData,
        ...{ id: dailyLog.id, projectId: dailyLog.projectId },
      }).unwrap();
      dispatch(showInfoAlert(dailyLog.id ? 'Successfully updated dailyLog' : 'Successfully created dailyLog'));
      if (matchPath(`/projects/${response.projectId}/dailyLogs/new`, location.pathname)) {
        navigate(`/projects/${response.projectId}/dailyLogs/${response.id}`, { replace: true });
      }
    } catch (error) {
      console.log(error);
      dispatch(showErrorAlert(error as AlertObject));
    } finally {
      setSaving(false);
    }
  }

  return (
    <Card style={{ backgroundColor: 'var(--joy-palette-common-white)' }}>
      <Box sx={{ mb: 1 }}>
        <Typography level="title-md">Daily log info</Typography>
      </Box>
      <Divider />
      <Stack direction="row" spacing={3} sx={{ display: { xs: 'flex' }, my: 1 }}>
        <Stack spacing={2} sx={{ flexGrow: 1 }}>
          <Grid container spacing={2}>
            <Grid xs={12} md={6}>
              <Controller
                control={control}
                name="logDate"
                render={({ field: { onChange, onBlur, value: controlValue } }) => (
                  <FormControl error={!!errors.logDate}>
                    <FormLabel>Log date</FormLabel>
                    <DatePickerComponent value={controlValue} onChange={onChange} disabled={!isAllowedUpdateDailyLog} />
                    {errors.logDate && <FormHelperText>{errors.logDate.message}</FormHelperText>}
                  </FormControl>
                )}
              />
            </Grid>
            <Grid xs={12} md={6}>
              <Controller
                control={control}
                name="type"
                render={({ field: { onChange, onBlur, value: controlValue } }) => (
                  <FormControl error={!!errors.type}>
                    <FormLabel>Type</FormLabel>
                    <Select
                      value={controlValue}
                      onChange={(e, newValue) => {
                        onChange(newValue);
                      }}
                      onBlur={onBlur}
                      disabled={!isAllowedUpdateDailyLog}
                    >
                      {dailyLogOptions.map((option) => (
                        <Option key={option.value} value={option.value}>
                          {option.label}
                        </Option>
                      ))}
                    </Select>
                    {errors.type && <FormHelperText>{errors.type.message}</FormHelperText>}
                  </FormControl>
                )}
              />
            </Grid>
            <Grid xs={12}>
              <Controller
                control={control}
                name="notes"
                render={({ field: { onChange, onBlur, value: controlValue } }) => (
                  <FormControl error={!!errors.notes}>
                    <FormLabel>Notes</FormLabel>
                    <Textarea
                      value={controlValue}
                      onBlur={onBlur}
                      onChange={onChange}
                      minRows={4}
                      disabled={!isAllowedUpdateDailyLog}
                    />
                    {errors.notes && <FormHelperText>{errors.notes.message}</FormHelperText>}
                  </FormControl>
                )}
              />
            </Grid>
            <Grid xs={12}>
              <Controller
                control={control}
                name="staffOnsiteUserIds"
                render={({ field: { onChange, onBlur, value: controlValue } }) => (
                  <FormControl error={!!errors.staffOnsiteUserIds}>
                    <FormLabel>Staff onsite</FormLabel>
                    <Select
                      value={controlValue}
                      onChange={(e, newValue) => {
                        onChange(newValue);
                      }}
                      onBlur={onBlur}
                      multiple={true}
                      disabled={!isAllowedUpdateDailyLog}
                    >
                      {users.map((option) => (
                        <Option key={option.value} value={option.value}>
                          {option.label}
                        </Option>
                      ))}
                    </Select>
                    {errors.staffOnsiteUserIds && <FormHelperText>{errors.staffOnsiteUserIds.message}</FormHelperText>}
                  </FormControl>
                )}
              />
            </Grid>
          </Grid>
        </Stack>
      </Stack>
      {isAllowedUpdateDailyLog && (
        <CardOverflow sx={{ borderTop: '1px solid', borderColor: 'divider' }}>
          <CardActions sx={{ alignSelf: 'flex-end', pt: 2 }}>
            <Button
              size="sm"
              variant="outlined"
              onClick={() => (dailyLog.id ? reset() : navigate(-1))}
              disabled={!isDirty && !!dailyLog.id}
            >
              Cancel
            </Button>
            <Button size="sm" variant="solid" onClick={handleSubmit(onSubmit)} disabled={!isDirty || saving}>
              Save
            </Button>
          </CardActions>
        </CardOverflow>
      )}
    </Card>
  );
}
