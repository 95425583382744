import { Add, ChevronRightRounded, HomeRounded } from '@mui/icons-material';
import { Box, Breadcrumbs, Button, Link, Tooltip, Typography } from '@mui/joy';
import { useNavigate } from 'react-router-dom';

import { canUserCreateProject } from '@builder-bud/common';
import { useAppSelector, useGetProjectsQuery } from '@builder-bud/common-ui';

import CircularProgressComponent from '../../components/circular-progress.component';
import ErrorLoadingComponent from '../../components/error-loading.component';
import ProjectTableComponent from './projects-table.component';

export default function ProjectsScreen() {
  const navigate = useNavigate();

  const me = useAppSelector((state) => state.auth.me);
  const { data: projects = [], isLoading, isFetching, isError, error } = useGetProjectsQuery();
  const isAllowedCreateProject = me && canUserCreateProject(me, projects.length);

  if (isLoading || isFetching) return <CircularProgressComponent />;
  if (isError) return <ErrorLoadingComponent error={error} />;

  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Breadcrumbs
          size="sm"
          aria-label="breadcrumbs"
          separator={<ChevronRightRounded fontSize="small" />}
          sx={{ pl: 0 }}
        >
          <Link underline="none" color="neutral" href="/" aria-label="Home">
            <HomeRounded />
          </Link>
          <Typography color="primary" fontWeight={500} fontSize={12}>
            Projects
          </Typography>
        </Breadcrumbs>
      </Box>
      <Box
        sx={{
          display: 'flex',
          mb: 1,
          gap: 1,
          flexDirection: { xs: 'column', sm: 'row' },
          alignItems: { xs: 'start', sm: 'center' },
          flexWrap: 'wrap',
          justifyContent: 'space-between',
        }}
      >
        <Typography level="h2" component="h1">
          Projects
        </Typography>
        <Tooltip
          title={
            isAllowedCreateProject
              ? ''
              : 'You have used the max projects for your current subscription. Please go to Settings > Subscriptions Management'
          }
          variant="solid"
          placement="top"
          arrow
          onClick={() => {
            navigate('settings/subscriptions');
          }}
          sx={{ zIndex: 20002 }}
        >
          <Button
            color="secondary"
            variant="solid"
            startDecorator={<Add />}
            onClick={() => navigate('/projects/new')}
            disabled={!isAllowedCreateProject}
          >
            Add project
          </Button>
        </Tooltip>
      </Box>
      <ProjectTableComponent rows={projects} />
    </>
  );
}
