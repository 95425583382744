import { ExpandMore } from '@mui/icons-material';
import { Chip, Dropdown, Menu, MenuButton, MenuItem, Stack, useTheme } from '@mui/joy';
import { useState } from 'react';

import { Contract, ContractStatus } from '@builder-bud/common';
import {
  AlertObject,
  BBListItem,
  CONTRACT_STATUS_OPTIONS,
  showErrorAlert,
  useAppDispatch,
  useEditContractMutation,
} from '@builder-bud/common-ui';

import CircularProgressComponent from './circular-progress.component';

function convertContractStatusValue(value: ContractStatus): BBListItem {
  const status = CONTRACT_STATUS_OPTIONS.find((option) => option.value === value);
  return status ? status : CONTRACT_STATUS_OPTIONS[0];
}

function ContractStatusComponent({ contract, allowUpdate = false }: { contract: Contract; allowUpdate?: boolean }) {
  const dispatch = useAppDispatch();
  const theme = useTheme();

  const [editContract] = useEditContractMutation();

  const [saving, setSaving] = useState(false);

  async function updateStatus(status: ContractStatus) {
    setSaving(true);
    try {
      await editContract({ projectId: contract.projectId, id: contract.id, status }).unwrap();
    } catch (error) {
      console.log(error);
      dispatch(showErrorAlert(error as AlertObject));
    } finally {
      setSaving(false);
    }
  }

  if (saving) return <CircularProgressComponent />;

  const statusOption = convertContractStatusValue(contract.status);

  return (
    <Stack alignItems="flex-start">
      <Dropdown>
        <MenuButton variant="plain" disabled={!allowUpdate}>
          <Chip
            sx={{
              minWidth: 90,
              fontSize: 12,
              color: theme.vars.palette.common.white,
              backgroundColor: `var(--joy-palette-${statusOption.color}-500)`,
            }}
            endDecorator={allowUpdate && <ExpandMore />}
          >
            {statusOption.label}
          </Chip>
        </MenuButton>
        <Menu size="sm" variant="plain">
          {CONTRACT_STATUS_OPTIONS.map((option) => {
            return (
              <MenuItem
                key={option.value}
                onClick={() => {
                  updateStatus(option.value as ContractStatus);
                }}
                sx={{
                  minWidth: 90,
                  fontSize: 12,
                  color: theme.vars.palette.common.white,
                  backgroundColor: `var(--joy-palette-${option.color}-500)`,
                }}
              >
                {option.label}
              </MenuItem>
            );
          })}
        </Menu>
      </Dropdown>
    </Stack>
  );
}

export default ContractStatusComponent;
