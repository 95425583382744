import {
  CreateProjectRequest,
  CreateProjectResponse,
  GetProjectResponse,
  GetProjectTagsResponse,
  GetProjectsResponse,
  Project,
  ProjectId,
  SendProjectNotificationRequest,
  UpdateProjectRequest,
  UpdateProjectResponse,
} from '@builder-bud/common';

import { api } from './api';

export const projectsSlice = api.injectEndpoints({
  overrideExisting: true,
  endpoints: (builder) => ({
    getProjects: builder.query<Project[], void>({
      query: () => ({
        url: '/projects',
        method: 'GET',
      }),
      transformResponse: (response: GetProjectsResponse) => {
        if ('data' in response) {
          return response.data;
        }
        throw new Error('Failed to get projects');
      },
      providesTags: ['projects'],
    }),
    getProject: builder.query<Project, ProjectId>({
      query: (id) => ({
        url: `/projects/${id}`,
        method: 'GET',
      }),
      transformResponse: (response: GetProjectResponse) => {
        if ('data' in response) {
          return response.data;
        }
        throw new Error('Failed to get project');
      },
      providesTags: ['projects'],
    }),
    createProject: builder.mutation<Project, CreateProjectRequest>({
      query: (body) => ({
        url: '/projects',
        method: 'POST',
        body,
      }),
      transformResponse: (response: CreateProjectResponse) => {
        if ('data' in response) {
          return response.data;
        }
        throw new Error('Failed to create project');
      },
      invalidatesTags: ['projects', 'me'],
    }),
    editProject: builder.mutation<Project, UpdateProjectRequest & { id: ProjectId }>({
      query: (body) => ({
        url: `/projects/${body.id}`,
        method: 'PATCH',
        body,
      }),
      transformResponse: (response: UpdateProjectResponse) => {
        if ('data' in response) {
          return response.data;
        }
        throw new Error('Failed to edit project');
      },
      invalidatesTags: ['projects'],
    }),
    deleteProject: builder.mutation<Project, { id: ProjectId }>({
      query: (body) => ({
        url: `/projects/${body.id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['projects'],
    }),
    getProjectTags: builder.query<string[], ProjectId>({
      query: (id) => ({
        url: `/projects/${id}/tags`,
        method: 'GET',
      }),
      transformResponse: (response: GetProjectTagsResponse) => {
        if ('data' in response) {
          return response.data;
        }
        throw new Error('Failed to get project');
      },
      providesTags: ['project_tags'],
    }),
    sendProjectNotification: builder.mutation<void, SendProjectNotificationRequest & { id: ProjectId }>({
      query: (body) => ({
        url: `projects/${body.id}/notifications`,
        method: 'POST',
        body,
      }),
    }),
  }),
});

export const {
  useGetProjectsQuery,
  useGetProjectQuery,
  useCreateProjectMutation,
  useEditProjectMutation,
  useDeleteProjectMutation,
  useGetProjectTagsQuery,
  useSendProjectNotificationMutation,
} = projectsSlice;
