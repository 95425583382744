import { Cancel, CheckCircle, UploadFile } from '@mui/icons-material';
import { Box, Button, Chip, FormControl, FormLabel, Input, Option, Select, Stack, Typography } from '@mui/joy';
import { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';

import { ContractId, DailyLogId, EventId, ProjectId, TaskId } from '@builder-bud/common';
import {
  FileUploadStatus,
  FileWithDescription,
  IMAGE_TYPE,
  closeModal,
  showErrorAlert,
  useAppDispatch,
  useCreateContractFileMutation,
  useCreateDailyLogFileMutation,
  useCreateEventFileMutation,
  useCreateFileMutation,
  useGetContractFileUploadUrlMutation,
  useGetDailyLogFileUploadUrlMutation,
  useGetEventFileUploadUrlMutation,
  useGetFileUploadUrlMutation,
  useGetProjectTagsQuery,
} from '@builder-bud/common-ui';

import CircularProgressComponent from '../../components/circular-progress.component';

export default function ProjectFileUploadModal({
  projectId,
  taskId,
  eventId,
  dailyLogId,
  contractId,
  defaultFileTag,
}: {
  projectId: ProjectId;
  taskId?: TaskId;
  eventId?: EventId;
  dailyLogId?: DailyLogId;
  contractId?: ContractId;
  defaultFileTag?: string;
}) {
  const dispatch = useAppDispatch();
  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  const [getFileUploadUrl] = useGetFileUploadUrlMutation();
  const [createFile] = useCreateFileMutation();

  const [getEventFileUploadUrl] = useGetEventFileUploadUrlMutation();
  const [createEventFile] = useCreateEventFileMutation();

  const [getDailyLogFileUploadUrl] = useGetDailyLogFileUploadUrlMutation();
  const [createDailyLogFile] = useCreateDailyLogFileMutation();

  const [getContractFileUploadUrl] = useGetContractFileUploadUrlMutation();
  const [createContractFile] = useCreateContractFileMutation();

  const [files, setFiles] = useState<FileWithDescription[]>([]);
  const [isFileUploading, setIsFileUploading] = useState<{ [key: string]: FileUploadStatus }>({});

  function onDrop(acceptedFiles: any) {
    if (acceptedFiles.length > 0) {
      const files = acceptedFiles.map((file: File) => {
        return {
          name: file.name,
          size: file.size,
          type: file.type,
          lastModified: file.lastModified,
          description: '',
          tags: defaultFileTag ? [defaultFileTag] : new Array<string>(),
          webFile: file,
          webFileUrl: file.type?.startsWith(IMAGE_TYPE) ? URL.createObjectURL(file) : undefined,
        };
      });
      setFiles(files as FileWithDescription[]);

      files.forEach((file: FileWithDescription) => {
        const image = document.getElementById(`${file.name}_${file.lastModified}`);
        if (image && file.webFileUrl) {
          image.setAttribute('src', file.webFileUrl);
        }
      });
    }
  }

  async function handleUploadFile() {
    if (!files) {
      dispatch(showErrorAlert({ message: 'Files not found' }));
      return;
    }

    for (const file of files) {
      try {
        isFileUploading[`${file.name}_${file.lastModified}`] = FileUploadStatus.InProgress;
        setIsFileUploading({ ...isFileUploading });

        let fileUploadUrlResult;
        if (eventId) {
          fileUploadUrlResult = await getEventFileUploadUrl({
            projectId,
            eventId,
            contentType: file.type,
          }).unwrap();
        } else if (dailyLogId) {
          fileUploadUrlResult = await getDailyLogFileUploadUrl({
            projectId,
            dailyLogId,
            contentType: file.type,
          }).unwrap();
        } else if (contractId) {
          fileUploadUrlResult = await getContractFileUploadUrl({
            projectId,
            contractId,
            contentType: file.type,
          }).unwrap();
        } else {
          fileUploadUrlResult = await getFileUploadUrl({ projectId, taskId, contentType: file.type }).unwrap();
        }

        const url = fileUploadUrlResult.url;

        const fileUploadResult = await fetch(url, {
          method: 'PUT',
          body: file.webFile,
          headers: { 'Content-Type': file.type },
        });

        if (fileUploadResult.status !== 200) {
          throw new Error('File upload failed');
        }

        const body = {
          projectId,
          id: fileUploadUrlResult.id,
          name: file.name,
          description: file.description === '' ? null : file.description,
          tags: file.tags,
          contentType: file.type,
          size: file.size,
        };

        if (eventId) {
          await createEventFile({ ...body, ...{ eventId } }).unwrap();
        } else if (dailyLogId) {
          await createDailyLogFile({ ...body, ...{ dailyLogId } }).unwrap();
        } else if (contractId) {
          await createContractFile({ ...body, ...{ contractId } }).unwrap();
        } else {
          await createFile({ ...body, ...{ taskId } }).unwrap();
        }

        isFileUploading[`${file.name}_${file.lastModified}`] = FileUploadStatus.Completed;
        setIsFileUploading({ ...isFileUploading });
      } catch (error) {
        console.log(error);
        isFileUploading[`${file.name}_${file.lastModified}`] = FileUploadStatus.Failed;
        setIsFileUploading({ ...isFileUploading });
      }
    }
  }

  useEffect(() => {
    // Make sure to revoke the object urls to avoid memory leaks
    return () => files.forEach((file) => file.webFileUrl && URL.revokeObjectURL(file.webFileUrl));
  }, [files]);

  const { data: projectTags = [] } = useGetProjectTagsQuery(projectId);

  return (
    <Stack
      sx={{ margin: 2, gap: 2, padding: 8, borderRadius: 'sm', border: '1px dashed ' }}
      {...(files.length > 0 ? {} : getRootProps())}
    >
      <Typography>Upload files</Typography>
      <Typography level="body-sm">Drag & drop or click here</Typography>
      <input {...getInputProps()} />

      {files.length > 0 && (
        <Stack flexDirection="row" columnGap={1} sx={{ marginBottom: -2 }}>
          <Stack sx={{ width: 32 }}></Stack>
          <Stack flex={1}>
            <FormControl required>
              <FormLabel sx={{ alignSelf: 'flex-start' }}>File name</FormLabel>
            </FormControl>
          </Stack>
          <Stack flex={1}>
            <FormControl>
              <FormLabel sx={{ alignSelf: 'flex-start' }}>Description</FormLabel>
            </FormControl>
          </Stack>
          <Stack flex={1}>
            <FormControl>
              <FormLabel sx={{ alignSelf: 'flex-start' }}>Tags</FormLabel>
            </FormControl>
          </Stack>
        </Stack>
      )}

      {files.map((file: FileWithDescription, i: number) => (
        <Stack key={i} flexDirection="row" columnGap={1} alignItems="center">
          {file.type?.startsWith(IMAGE_TYPE) && file.webFileUrl ? (
            <img id={`${file.name}_${file.lastModified}`} height={32} width={32} src={file.webFileUrl} alt="" />
          ) : (
            <UploadFile />
          )}

          <FormControl error={file.name.length === 0} required>
            <Input
              value={file.name}
              onChange={(e) => {
                setFiles(
                  files.map((f: FileWithDescription, index: number) => {
                    if (i === index) {
                      return { ...f, name: e.target.value };
                    } else {
                      return f;
                    }
                  })
                );
              }}
              disabled={Object.keys(isFileUploading).length !== 0}
            />
          </FormControl>
          <FormControl>
            <Input
              value={file.description}
              onChange={(e) => {
                setFiles(
                  files.map((f: FileWithDescription, index: number) => {
                    if (i === index) {
                      return { ...f, description: e.target.value };
                    } else {
                      return f;
                    }
                  })
                );
              }}
              disabled={Object.keys(isFileUploading).length !== 0}
            />
          </FormControl>
          <FormControl>
            <Select
              multiple
              value={file.tags}
              onChange={(e, newValue) => {
                setFiles(
                  files.map((f: FileWithDescription, index: number) => {
                    if (i === index) {
                      return { ...f, tags: newValue };
                    } else {
                      return f;
                    }
                  })
                );
              }}
              disabled={Object.keys(isFileUploading).length !== 0}
              renderValue={(selected) => (
                <Box sx={{ display: 'flex', gap: '0.25rem' }}>
                  {selected.map((selectedOption) => (
                    <Chip key={selectedOption.value} variant="soft" color="secondary">
                      {selectedOption.label}
                    </Chip>
                  ))}
                </Box>
              )}
              sx={{ minWidth: '15rem' }}
              slotProps={{
                listbox: {
                  sx: {
                    width: '100%',
                    zIndex: 20001,
                  },
                },
              }}
            >
              {projectTags.map((tag) => (
                <Option key={tag} value={tag} disabled={tag === defaultFileTag}>
                  {tag}
                </Option>
              ))}
            </Select>
          </FormControl>

          <Stack>
            {isFileUploading[`${file.name}_${file.lastModified}`] === FileUploadStatus.InProgress && (
              <CircularProgressComponent size="sm" />
            )}
            {isFileUploading[`${file.name}_${file.lastModified}`] === FileUploadStatus.Completed && (
              <CheckCircle color="primary" />
            )}
            {isFileUploading[`${file.name}_${file.lastModified}`] === FileUploadStatus.Failed && (
              <Cancel color="error" />
            )}
          </Stack>
        </Stack>
      ))}

      {Object.keys(isFileUploading).length === 0 ? (
        <Button
          onClick={handleUploadFile}
          disabled={!files || files.length === 0 || files.some((file: FileWithDescription) => file.name === '')}
          loading={Object.values(isFileUploading).some((value) => value === FileUploadStatus.InProgress)}
        >
          Upload
        </Button>
      ) : (
        <Button
          onClick={(e) => {
            dispatch(closeModal());
          }}
          disabled={Object.values(isFileUploading).some((value) => value === FileUploadStatus.InProgress)}
        >
          Close
        </Button>
      )}
    </Stack>
  );
}
