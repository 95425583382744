import { Box, Button, Card, CardActions, CardOverflow, Divider, Grid, Stack, Typography } from '@mui/joy';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import SignaturePad from 'react-signature-pad-wrapper';

import { Contract, ContractStatus, File, FileWithUrl, canUserApproveContract } from '@builder-bud/common';
import {
  AlertObject,
  getContractTypeLabel,
  getIsInvoice,
  getUserProjectRole,
  showErrorAlert,
  showInfoAlert,
  useAppDispatch,
  useAppSelector,
  useApproveContractMutation,
} from '@builder-bud/common-ui';

import CircularProgressComponent from '../../components/circular-progress.component';
import DateTimeComponent from '../../components/date-time.component';
import DateComponent from '../../components/date.component';
import MemberComponent from '../../components/member.component';
import FilesTableComponent from '../files/files-table.component';
import CompanyComponent from './company.component';
import ContractLineItemTableComponent from './contract-line-item-table.component';
import HomeownersComponent from './homeowners.component';

export default function ContractView({ contract, files }: { contract: Contract; files: File[] }) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [approveContract] = useApproveContractMutation();

  const me = useAppSelector((state) => state.auth.me);
  const userProjectRole = getUserProjectRole(contract.projectId, me?.projects);
  const isAllowedApproveContract = userProjectRole && canUserApproveContract(userProjectRole);

  const [saving, setSaving] = useState(false);
  const [isDirty, setIsDirty] = useState(false);
  const signaturePadRef = useRef<SignaturePad | null>(null);

  async function handleApprove() {
    setSaving(true);
    try {
      if (contract.signatureRequired) {
        if (signaturePadRef.current) {
          await approveContract({
            signature: signaturePadRef.current.toDataURL(),
            contractId: contract.id,
            projectId: contract.projectId,
          }).unwrap();
          dispatch(showInfoAlert('Successfully signed work order'));
        } else {
          console.log('Signature pad not initialized properly');
        }
      } else {
        await approveContract({
          contractId: contract.id,
          projectId: contract.projectId,
        }).unwrap();
        dispatch(showInfoAlert('Successfully approved work order'));
      }
    } catch (error) {
      console.log(error);
      dispatch(showErrorAlert(error as AlertObject));
    } finally {
      setSaving(false);
    }
  }

  useEffect(() => {
    const signaturePad = signaturePadRef.current?.instance;

    signaturePad?.addEventListener('beginStroke', () => {
      setIsDirty(true);
    });

    return () => {
      signaturePad?.removeEventListener('beginStroke', null);
    };
  });

  return (
    <Stack rowGap={2}>
      <Card sx={{ backgroundColor: 'var(--joy-palette-common-white)' }}>
        <Box sx={{ mb: 1 }}>
          <Typography level="title-md">{getContractTypeLabel(contract.type)}</Typography>
        </Box>
        <Divider />
        <Stack
          direction="row"
          spacing={3}
          sx={{
            display: { xs: 'flex' },
            my: 1,
            justifyContent: 'space-between',
          }}
        >
          <HomeownersComponent projectId={contract.projectId} />
          <CompanyComponent userId={contract.createdByUserId} />
        </Stack>

        <Divider />

        <Grid container xs={12} spacing={2}>
          <Grid container xs={12} sm={8} spacing={2}>
            <Grid xs={12}>
              <Typography level="body-md">Name: {contract.name}</Typography>
              <Typography level="body-md">Description: {contract.description}</Typography>
            </Grid>
          </Grid>
          <Grid container xs={12} sm={4} spacing={2}>
            <Grid xs={12}>
              <Typography level="body-md">Reference: {contract.reference}</Typography>
              <Typography level="body-md">Terms: {contract.terms}</Typography>
              {getIsInvoice(contract.type) && (
                <Typography level="body-md">
                  Invoice Date: <DateComponent date={contract.invoiceDate} level="body-md" />
                </Typography>
              )}
              <Typography level="body-md">
                Due Date: <DateComponent date={contract.dueDate} level="body-md" />
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        <ContractLineItemTableComponent
          projectId={contract.projectId}
          contractType={contract.type}
          contractStatus={contract.status}
          rows={contract.lineItems ? contract.lineItems : []}
          homeownerView={true}
        />

        {files.length > 0 && (
          <>
            <Typography level="title-md">Files</Typography>
            <FilesTableComponent rows={files as FileWithUrl[]} hideSearch={true} hideRowMenu={true} />
          </>
        )}

        {(contract.status === ContractStatus.Approved || contract.signatureRequired) && <Divider />}

        {contract.status === ContractStatus.Approved ? (
          <Stack sx={{ mb: 1 }} direction="row" justifyContent="space-between" alignItems="center">
            <Stack>{contract.signature && <img src={contract.signature} height={160} alt="" />}</Stack>
            <Stack gap={1}>
              <Typography>Approved By:</Typography>
              <MemberComponent userId={contract.approvedByUserId} showName />
              <DateTimeComponent date={contract.approvedAt} />
            </Stack>
          </Stack>
        ) : (
          <Stack>
            {contract.signatureRequired && (
              <Stack direction="row" spacing={3} sx={{ display: { xs: 'flex' }, my: 1, height: 200 }}>
                {saving ? (
                  <CircularProgressComponent />
                ) : (
                  contract.signatureRequired && (
                    <SignaturePad
                      ref={signaturePadRef}
                      options={{ backgroundColor: 'white' }} //Can't use the joy variable with SignaturePad
                    />
                  )
                )}
              </Stack>
            )}
            <CardOverflow sx={{ borderTop: '1px solid', borderColor: 'divider' }}>
              <CardActions sx={{ alignSelf: 'flex-end', pt: 2 }}>
                <Button size="sm" variant="outlined" onClick={() => navigate(-1)} disabled={!isAllowedApproveContract}>
                  Cancel
                </Button>
                {contract.signatureRequired ? (
                  <>
                    <Button
                      size="sm"
                      variant="outlined"
                      onClick={() => {
                        signaturePadRef.current?.clear();
                        setIsDirty(false);
                      }}
                      disabled={!isAllowedApproveContract || signaturePadRef.current?.isEmpty() || saving}
                    >
                      Clear
                    </Button>
                    <Button
                      size="sm"
                      variant="solid"
                      onClick={handleApprove}
                      disabled={!isAllowedApproveContract || !isDirty || saving}
                    >
                      Sign
                    </Button>
                  </>
                ) : (
                  <Button
                    size="sm"
                    variant="solid"
                    onClick={handleApprove}
                    disabled={!isAllowedApproveContract || saving}
                  >
                    Approve
                  </Button>
                )}
              </CardActions>
            </CardOverflow>
          </Stack>
        )}
      </Card>
    </Stack>
  );
}
