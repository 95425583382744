import { CopyAll, Map, Photo } from '@mui/icons-material';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardCover,
  CardOverflow,
  Divider,
  FormControl,
  FormHelperText,
  FormLabel,
  Grid,
  IconButton,
  Input,
  Option,
  Select,
  Stack,
  Textarea,
  Typography,
} from '@mui/joy';
import { ReactElement, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { matchPath, useLocation, useNavigate } from 'react-router-dom';

import { FileType, Project, UserProjectRole, canUpdateProjectAttribute } from '@builder-bud/common';
import {
  AlertObject,
  PROJECT_STATUS_OPTIONS,
  ProjectFormData,
  ProjectSchemaResolver,
  getDefaultProjectFormValues,
  getProjectSubmitData,
  getUserProjectRole,
  selectIsHomeownerAppRole,
  showErrorAlert,
  showInfoAlert,
  useAppDispatch,
  useAppSelector,
} from '@builder-bud/common-ui';

import { DatePickerComponent } from '../../components/date-picker.component';
import GooglePlacesAutocomplete from '../../components/google-places-autocomplete.component';
import ProjectPhotoUploadComponent from '../files/project-photo-upload.component';

export default function ProjectForm({ project, saveProject }: { project: Partial<Project>; saveProject: any }) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const me = useAppSelector((state) => state.auth.me);
  const isHomeowner = useAppSelector(selectIsHomeownerAppRole);
  let userProjectRole = getUserProjectRole(project.id, me?.projects);

  //TODO need to revisit this when we have the concept of a homeowner who is acting as a GC
  if (!userProjectRole) {
    userProjectRole = isHomeowner ? UserProjectRole.Homeowner : UserProjectRole.Contractor;
  }

  const isAllowedUpdateProject =
    userProjectRole === UserProjectRole.Contractor || userProjectRole === UserProjectRole.Homeowner;

  const [saving, setSaving] = useState(false);

  const {
    control,
    getValues,
    handleSubmit,
    setValue,
    reset,
    formState: { errors, isDirty },
  } = useForm<ProjectFormData>({
    defaultValues: getDefaultProjectFormValues(project),
    resolver: ProjectSchemaResolver,
  });

  async function onSubmit(data: ProjectFormData) {
    setSaving(true);
    try {
      const projectData = getProjectSubmitData(data);

      //Strip out any keys the user isn't allowed to update before sending the request
      for (const property in projectData) {
        if (!canUpdateProjectAttribute(userProjectRole!, property as any)) {
          delete (projectData as Project)[property as keyof Project];
        }
      }

      const response = await saveProject({ ...projectData, ...{ id: project.id } }).unwrap();
      dispatch(showInfoAlert(project.id ? 'Successfully updated project' : 'Successfully created project'));
      if (matchPath('/projects/new', location.pathname)) {
        navigate(`/projects/${response.id}`, { replace: true });
      }
    } catch (error) {
      console.log(error);
      dispatch(showErrorAlert(error as AlertObject));
    } finally {
      setSaving(false);
    }
  }

  const cardPlaceHolder: ReactElement = (
    <CardContent style={{ justifyContent: 'center', alignItems: 'center' }}>
      <Photo style={{ fontSize: 120 }} />
    </CardContent>
  );

  const displayAddress = getValues('displayAddress');

  return (
    <Card style={{ backgroundColor: 'var(--joy-palette-common-white)' }}>
      <Box sx={{ mb: 1 }}>
        <Typography level="title-md">Project info</Typography>
      </Box>
      <Divider />
      <Stack direction="row" spacing={3} sx={{ display: { xs: 'flex' }, my: 1 }}>
        <Stack spacing={2} sx={{ flexGrow: 1 }}>
          <Grid container spacing={2}>
            <Grid xs={12} md={5}>
              {project && project.id ? (
                <Stack>
                  <ProjectPhotoUploadComponent
                    project={project as Project}
                    placeHolder={cardPlaceHolder}
                    fileType={FileType.ProjectCoverImage}
                    disabled={!isAllowedUpdateProject}
                  />
                </Stack>
              ) : (
                <Card sx={{ display: 'flex', flex: 1, minHeight: 320 }}>
                  <CardCover>{cardPlaceHolder}</CardCover>
                  <Typography level="body-sm" color="warning" sx={{alignSelf:'center'}}>Save project first to add photo</Typography>
                </Card>
              )}
            </Grid>
            <Grid container xs={12} md={7} spacing={2}>
              <Grid xs={12}>
                <Controller
                  control={control}
                  name="name"
                  render={({ field: { onChange, onBlur, value: controlValue } }) => (
                    <FormControl error={!!errors.name} required>
                      <FormLabel>Project name</FormLabel>
                      <Input
                        value={controlValue}
                        onBlur={onBlur}
                        onChange={onChange}
                        disabled={!canUpdateProjectAttribute(userProjectRole, 'name')}
                      />
                      {errors.name && <FormHelperText>{errors.name.message}</FormHelperText>}
                    </FormControl>
                  )}
                />
              </Grid>

              <Grid xs={12}>
                <Controller
                  control={control}
                  name="startDate"
                  render={({ field: { onChange, onBlur, value: controlValue } }) => (
                    <FormControl error={!!errors.startDate}>
                      <FormLabel>Start Date</FormLabel>
                      <DatePickerComponent
                        value={controlValue}
                        onChange={onChange}
                        disabled={!canUpdateProjectAttribute(userProjectRole, 'startDate')}
                      />
                      {errors.startDate && <FormHelperText>{errors.startDate.message}</FormHelperText>}
                    </FormControl>
                  )}
                />
              </Grid>

              <Grid xs={12}>
                <Controller
                  control={control}
                  name="status"
                  render={({ field: { onChange, onBlur, value: controlValue } }) => (
                    <FormControl error={!!errors.status}>
                      <FormLabel>Status</FormLabel>
                      <Select
                        value={controlValue}
                        onChange={(e, newValue) => {
                          onChange(newValue);
                        }}
                        disabled={!canUpdateProjectAttribute(userProjectRole, 'status')}
                      >
                        {PROJECT_STATUS_OPTIONS.map((option, i) => (
                          <Option key={option.value} value={option.value}>
                            {option.label}
                          </Option>
                        ))}
                      </Select>
                      {errors.status && <FormHelperText>{errors.status.message}</FormHelperText>}
                    </FormControl>
                  )}
                />
              </Grid>

              <Grid xs={12}>
                <Controller
                  control={control}
                  name="displayAddress"
                  render={({ field: { onChange, onBlur, value: controlValue } }) => (
                    <FormControl error={!!errors.displayAddress} required>
                      <FormLabel>Address</FormLabel>
                      <Stack flexDirection="row" gap={1}>
                        <GooglePlacesAutocomplete
                          value={controlValue}
                          setValue={setValue}
                          onChange={onChange}
                          disabled={!canUpdateProjectAttribute(userProjectRole, 'addressLine1')}
                        />
                        <IconButton
                          onClick={() => {
                            navigator.clipboard.writeText(displayAddress);
                          }}
                          variant="outlined"
                          color="neutral"
                          size="sm"
                          disabled={!displayAddress}
                        >
                          <CopyAll />
                        </IconButton>
                        <IconButton
                          onClick={() => {
                            window.open(`http://maps.google.com/?q=${displayAddress}`, '_blank');
                          }}
                          variant="outlined"
                          color="neutral"
                          size="sm"
                          disabled={!displayAddress}
                        >
                          <Map />
                        </IconButton>
                      </Stack>
                      {errors.displayAddress && <FormHelperText>{errors.displayAddress.message}</FormHelperText>}
                    </FormControl>
                  )}
                />
              </Grid>
            </Grid>

            <Grid xs={12}>
              <Controller
                control={control}
                name="description"
                render={({ field: { onChange, onBlur, value: controlValue } }) => (
                  <FormControl error={!!errors.description}>
                    <FormLabel>Description</FormLabel>
                    <Textarea
                      value={controlValue}
                      onBlur={onBlur}
                      onChange={onChange}
                      minRows={2}
                      disabled={!canUpdateProjectAttribute(userProjectRole, 'description')}
                    />
                    {errors.description && <FormHelperText>{errors.description.message}</FormHelperText>}
                  </FormControl>
                )}
              />
            </Grid>
            <Grid xs={12}>
              <Controller
                control={control}
                name="permit"
                render={({ field: { onChange, onBlur, value: controlValue } }) => (
                  <FormControl error={!!errors.permit}>
                    <FormLabel>Permit</FormLabel>
                    <Textarea
                      value={controlValue}
                      onBlur={onBlur}
                      onChange={onChange}
                      minRows={2}
                      disabled={!canUpdateProjectAttribute(userProjectRole, 'permit')}
                    />
                    {errors.permit && <FormHelperText>{errors.permit.message}</FormHelperText>}
                  </FormControl>
                )}
              />
            </Grid>
            {userProjectRole !== UserProjectRole.Homeowner && (
              <>
                <Grid xs={12}>
                  <Controller
                    control={control}
                    name="homeDetails"
                    render={({ field: { onChange, onBlur, value: controlValue } }) => (
                      <FormControl error={!!errors.permit}>
                        <FormLabel>Home details</FormLabel>
                        <Textarea
                          value={controlValue}
                          onBlur={onBlur}
                          onChange={onChange}
                          minRows={2}
                          disabled={!canUpdateProjectAttribute(userProjectRole, 'homeDetails')}
                        />
                        {errors.homeDetails && <FormHelperText>{errors.homeDetails.message}</FormHelperText>}
                      </FormControl>
                    )}
                  />
                </Grid>
                <Grid xs={12}>
                  <Controller
                    control={control}
                    name="notes"
                    render={({ field: { onChange, onBlur, value: controlValue } }) => (
                      <FormControl error={!!errors.notes}>
                        <FormLabel>Notes</FormLabel>
                        <Textarea
                          value={controlValue}
                          onBlur={onBlur}
                          onChange={onChange}
                          minRows={4}
                          disabled={!canUpdateProjectAttribute(userProjectRole, 'notes')}
                        />
                        {errors.notes && <FormHelperText>{errors.notes.message}</FormHelperText>}
                      </FormControl>
                    )}
                  />
                </Grid>
              </>
            )}
          </Grid>
        </Stack>
      </Stack>
      {isAllowedUpdateProject && (
        <CardOverflow sx={{ borderTop: '1px solid', borderColor: 'divider' }}>
          <CardActions sx={{ alignSelf: 'flex-end', pt: 2 }}>
            <Button
              size="sm"
              variant="outlined"
              onClick={() => (project.id ? reset() : navigate(-1))}
              disabled={!isDirty && !!project.id}
            >
              Cancel
            </Button>
            <Button size="sm" variant="solid" onClick={handleSubmit(onSubmit)} disabled={!isDirty || saving}>
              Save
            </Button>
          </CardActions>
        </CardOverflow>
      )}
    </Card>
  );
}
