import { Upload } from '@mui/icons-material';
import { Avatar, Card, CardCover, Dropdown, Menu, MenuButton, MenuItem } from '@mui/joy';
import { ReactElement } from 'react';

import { FileType, Project } from '@builder-bud/common';
import { openModal, useAppDispatch } from '@builder-bud/common-ui';

import ProjectPhotoUploadModal from './project-photo-upload.modal';

export default function ProjectPhotoUploadComponent(
  this: any,
  {
    project,
    placeHolder,
    fileType,
    disabled=false,
  }: {
    project: Project;
    placeHolder: ReactElement;
    fileType: string;
    disabled?: boolean;
  }
) {
  const dispatch = useAppDispatch();

  const uploadProjectPhotoModal = <ProjectPhotoUploadModal projectId={project.id} fileType={fileType} />;
  const viewProjectPhotoModal = <img src={project.coverImageUrl} loading="lazy" alt="" />;

  return (
    <Dropdown>
      <MenuButton sx={{ border: 'none', p: 0 }}>
        {fileType === FileType.ProjectCoverImage && (
          <Card sx={{ display: 'flex', flex: 1, minHeight: 320 }}>
            {project.coverImageUrl ? (
              <CardCover>
                <img src={project.coverImageUrl} alt="" />
              </CardCover>
            ) : (
              placeHolder
            )}
            {!disabled &&
              <Avatar size="sm" color="neutral" sx={styles.coverIcon}>
                <Upload />
              </Avatar>
            }
          </Card>
        )}
      </MenuButton>
      <Menu>
        {project.coverImageUrl && (
          <MenuItem onClick={() => dispatch(openModal(viewProjectPhotoModal))}>View Photo</MenuItem>
        )}
        {!disabled &&
          <MenuItem onClick={() => dispatch(openModal(uploadProjectPhotoModal))}>Upload Photo</MenuItem>
        }
      </Menu>
    </Dropdown>
  );
}

const styles = {
  avatar: {
    height: 128,
    width: 128,
  },
  avatarIcon: {
    position: 'relative',
    bottom: 40,
    left: 96,
  },
  coverIcon: {
    position: 'absolute',
    right: 16,
    bottom: 16,
  },
};
