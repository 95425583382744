import {
  ApproveContractRequest,
  Contract,
  ContractId,
  ContractLineItem,
  ContractLineItemId,
  CreateContractLineItemRequest,
  CreateContractLineItemResponse,
  CreateContractRequest,
  CreateContractResponse,
  CreateFileResponse,
  File,
  FileId,
  GetContractResponse,
  GetContractsResponse,
  GetFilesResponse,
  GetSignedDownloadUrlResponse,
  GetSignedUploadUrlResponse,
  ProjectId,
  SignedDownloadUrlData,
  SignedUploadUrlData,
  UpdateContractLineItemRequest,
  UpdateContractLineItemResponse,
  UpdateContractRequest,
  UpdateContractResponse,
  UpdateFileRequest,
  UpdateFileResponse,
} from '@builder-bud/common';

import { api } from './api';

export const contractsSlice = api.injectEndpoints({
  overrideExisting: true,
  endpoints: (builder) => ({
    getContracts: builder.query<Contract[], { projectId: ProjectId }>({
      query: (body) => ({
        url: `projects/${body.projectId}/contracts`,
        method: 'GET',
      }),
      transformResponse: (response: GetContractsResponse) => {
        if ('data' in response) {
          return response.data;
        }
        throw new Error('Failed to get contracts');
      },
      providesTags: ['project_contracts'],
    }),
    getContract: builder.query<Contract, { projectId: ProjectId; contractId: ContractId }>({
      query: (body) => ({
        url: `/projects/${body.projectId}/contracts/${body.contractId}`,
        method: 'GET',
      }),
      transformResponse: (response: GetContractResponse) => {
        if ('data' in response) {
          return response.data;
        }
        throw new Error('Failed to get contract');
      },
      providesTags: ['project_contracts'],
    }),
    createContract: builder.mutation<Contract, CreateContractRequest & { projectId: ProjectId }>({
      query: (body) => ({
        url: `projects/${body.projectId}/contracts`,
        method: 'POST',
        body,
      }),
      transformResponse: (response: CreateContractResponse) => {
        if ('data' in response) {
          return response.data;
        }
        throw new Error('Failed to create contract');
      },
      invalidatesTags: ['project_contracts'],
    }),
    editContract: builder.mutation<Contract, UpdateContractRequest & { id: ContractId; projectId: ProjectId }>({
      query: (body) => ({
        url: `projects/${body.projectId}/contracts/${body.id}`,
        method: 'PATCH',
        body,
      }),
      transformResponse: (response: UpdateContractResponse) => {
        if ('data' in response) {
          return response.data;
        }
        throw new Error('Failed to edit contract');
      },
      invalidatesTags: ['project_contracts'],
    }),
    deleteContract: builder.mutation<Contract, { id: ContractId; projectId: ProjectId }>({
      query: (body) => ({
        url: `projects/${body.projectId}/contracts/${body.id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['project_contracts'],
    }),

    createContractLineItem: builder.mutation<
      ContractLineItem,
      CreateContractLineItemRequest & { contractId: ContractId; projectId: ProjectId }
    >({
      query: (body) => ({
        url: `projects/${body.projectId}/contracts/${body.contractId}/line-items`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['project_contracts'],
      transformResponse: (response: CreateContractLineItemResponse) => {
        if ('data' in response) {
          return response.data;
        }
        throw new Error('Failed to create contract');
      },
    }),
    editContractLineItem: builder.mutation<
      ContractLineItem,
      UpdateContractLineItemRequest & {
        contractLineItemId: ContractLineItemId;
        contractId: ContractId;
        projectId: ProjectId;
      }
    >({
      query: (body) => ({
        url: `projects/${body.projectId}/contracts/${body.contractId}/line-items/${body.contractLineItemId}`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: ['project_contracts'],
      transformResponse: (response: UpdateContractLineItemResponse) => {
        if ('data' in response) {
          return response.data;
        }
        throw new Error('Failed to edit contract');
      },
    }),
    deleteContractLineItem: builder.mutation<
      ContractLineItem,
      { contractLineItemId: ContractLineItemId; contractId: ContractId; projectId: ProjectId }
    >({
      query: (body) => ({
        url: `projects/${body.projectId}/contracts/${body.contractId}/line-items/${body.contractLineItemId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['project_contracts'],
      //Todo: This probably won't be needed once refetchOnMountOrArgChange is enabled for RTK
      /*async onQueryStarted({ contractLineItemId, contractId, projectId }, { dispatch, queryFulfilled }) {
        await queryFulfilled;
        dispatch(
          contractsSlice.util.updateQueryData('getContract', { contractId, projectId }, (draftContract) => {
            const lineItems = draftContract.lineItems?.filter(
              (lineItem: ContractLineItem) => lineItem.id !== contractLineItemId
            );
            return { ...draftContract, lineItems: lineItems };
          })
        );
      },*/
    }),
    approveContract: builder.mutation<
      Contract,
      ApproveContractRequest & { contractId: ContractId; projectId: ProjectId }
    >({
      query: (body) => ({
        url: `projects/${body.projectId}/contracts/${body.contractId}/approve`,
        method: 'POST',
        body,
      }),
      transformResponse: (response: CreateContractResponse) => {
        if ('data' in response) {
          return response.data;
        }
        throw new Error('Failed to sign contract');
      },
      invalidatesTags: ['project_contracts'],
    }),

    getContractFiles: builder.query<File[], { projectId: ProjectId; contractId?: ContractId }>({
      query: (body) => ({
        url: `/projects/${body.projectId}/contracts/${body.contractId}/files`,
        method: 'GET',
      }),
      transformResponse: (response: GetFilesResponse) => {
        if ('data' in response) {
          return response.data;
        }
        throw new Error('Failed to get files');
      },
      providesTags: ['project_contract_files'],
    }),
    getContractFileUploadUrl: builder.mutation<
      SignedUploadUrlData,
      { projectId: string; contractId: ContractId; contentType?: string }
    >({
      query: (body) => ({
        url: `/projects/${body.projectId}/contracts/${body.contractId}/files/upload-url?contentType=${body.contentType}`,
        method: 'GET',
      }),
      transformResponse: (response: GetSignedUploadUrlResponse) => {
        if ('data' in response) {
          return response.data;
        }
        throw new Error('Failed to get message file upload url');
      },
    }),
    createContractFile: builder.mutation<File, { projectId: ProjectId; contractId: ContractId }>({
      query: (body) => ({
        url: `/projects/${body.projectId}/contracts/${body.contractId}/files`,
        method: 'POST',
        body,
      }),
      transformResponse: (response: CreateFileResponse) => {
        if ('data' in response) {
          return response.data;
        }
        throw new Error('Failed to create message file');
      },
      invalidatesTags: ['project_contracts', 'project_contract_files'],
    }),
    getContractFileDownloadUrl: builder.mutation<
      SignedDownloadUrlData,
      { projectId: ProjectId; contractId: ContractId; fileId: FileId }
    >({
      query: (body) => ({
        url: `/projects/${body.projectId}/contracts/${body.contractId}/files/${body.fileId}/download-url`,
        method: 'GET',
      }),
      transformResponse: (response: GetSignedDownloadUrlResponse) => {
        if ('data' in response) {
          return response.data;
        }
        throw new Error('Failed to get message file download url');
      },
    }),
    editContractFile: builder.mutation<
      File,
      UpdateFileRequest & { projectId: ProjectId; contractId: ContractId; fileId: FileId }
    >({
      query: (body) => ({
        url: `/projects/${body.projectId}/contracts/${body.contractId}/files/${body.fileId}`,
        method: 'PATCH',
        body,
      }),
      transformResponse: (response: UpdateFileResponse) => {
        if ('data' in response) {
          return response.data;
        }
        throw new Error('Failed to edit file');
      },
      invalidatesTags: ['project_contracts', 'project_contract_files'],
    }),
    deleteContractFile: builder.mutation<File, { projectId: ProjectId; contractId: ContractId; fileId: FileId }>({
      query: (body) => ({
        url: `/projects/${body.projectId}/contracts/${body.contractId}/files/${body.fileId}`,
        method: 'DELETE',
        body,
      }),
      invalidatesTags: ['project_contract_files'],
    }),
  }),
});

export const {
  useGetContractsQuery,
  useGetContractQuery,
  useCreateContractMutation,
  useEditContractMutation,
  useDeleteContractMutation,
  useCreateContractLineItemMutation,
  useEditContractLineItemMutation,
  useDeleteContractLineItemMutation,
  useApproveContractMutation,
  useGetContractFilesQuery,
  useGetContractFileUploadUrlMutation,
  useCreateContractFileMutation,
  useGetContractFileDownloadUrlMutation,
  useEditContractFileMutation,
  useDeleteContractFileMutation,
} = contractsSlice;
