import { Add, ChevronRightRounded, HomeRounded } from '@mui/icons-material';
import {
  Box,
  Breadcrumbs,
  Dropdown,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  Tab,
  TabList,
  TabPanel,
  Tabs,
  Typography,
  tabClasses,
} from '@mui/joy';
import { matchPath, useLocation, useNavigate } from 'react-router-dom';

import { ProjectId, TaskId, TaskWithFiles } from '@builder-bud/common';
import {
  getUserOptions,
  isActiveProject,
  openModal,
  useAppDispatch,
  useEditTaskMutation,
  useGetProjectQuery,
  useGetTaskQuery,
  useGetUsersQuery,
} from '@builder-bud/common-ui';

import CircularProgressComponent from '../../../components/circular-progress.component';
import ErrorLoadingComponent from '../../../components/error-loading.component';
import NotFoundComponent from '../../../components/not-found.component';
import { useRequiredParams } from '../../../utils';
import FilesTableComponent from '../../files/files-table.component';
import FileUploadModal from '../../files/project-file-upload.modal';
import PunchlistTableComponent from './punchlist-table.component';
import PunchlistForm from './punchlist.form';

export default function PunchlistDetailsScreen() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { projectId } = useRequiredParams<{ projectId: ProjectId }>();
  const { taskId } = useRequiredParams<{ taskId: TaskId }>();
  const [updateTask] = useEditTaskMutation();

  const modalContent = <FileUploadModal projectId={projectId} taskId={taskId} />;

  const { data: project } = useGetProjectQuery(projectId);

  const {
    data: task,
    isLoading: isTaskLoading,
    isFetching: isTaskFetching,
    isError: isTaskError,
    error: taskError = {},
  } = useGetTaskQuery({ projectId, taskId });

  const {
    data: users = [],
    isLoading: isUsersLoading,
    isFetching: isUsersFetching,
    isError: isUsersError,
    error: usersError = {},
  } = useGetUsersQuery();

  const isLoading = isTaskLoading || isUsersLoading;
  const isFetching = isTaskFetching || isUsersFetching;
  const isError = isTaskError || isUsersError;
  const error = isTaskError ? taskError : isUsersError ? usersError : {};

  if (isLoading || isFetching) return <CircularProgressComponent />;
  if (isError) return <ErrorLoadingComponent error={error} />;
  if (!task) return <NotFoundComponent />;

  const getDefaultTabValue = () => {
    return matchPath('projects/:projectId/tasks/:parentTaskId/punchlist/:taskId/files', location.pathname) ? 2 : 0;
  };

  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Breadcrumbs
          size="sm"
          aria-label="breadcrumbs"
          separator={<ChevronRightRounded fontSize="small" />}
          sx={{ pl: 0 }}
        >
          <Link underline="none" color="neutral" href="/" aria-label="Home">
            <HomeRounded />
          </Link>
          <Link underline="hover" color="neutral" href="/projects" fontSize={12} fontWeight={500}>
            Projects
          </Link>
          <Link underline="hover" color="neutral" href={`/projects/${task.projectId}`} fontSize={12} fontWeight={500}>
            {task.projectId}
          </Link>
          <Link
            underline="hover"
            color="neutral"
            href={`/projects/${task.projectId}/tasks`}
            fontSize={12}
            fontWeight={500}
          >
            Tasks
          </Link>
          <Link
            underline="hover"
            color="neutral"
            href={`/projects/${task.projectId}/tasks/${task.parentTaskId}`}
            fontSize={12}
            fontWeight={500}
          >
            {task.parentTaskId}
          </Link>

          <Link
            underline="hover"
            color="neutral"
            href={`/projects/${task.projectId}/tasks/${task.parentTaskId}/punchlist`}
            fontSize={12}
            fontWeight={500}
          >
            Punchlist
          </Link>
          <Typography color="primary" fontWeight={500} fontSize={12}>
            {task.id}
          </Typography>
        </Breadcrumbs>
      </Box>
      <Box
        sx={{
          display: 'flex',
          mb: 1,
          gap: 1,
          flexDirection: { xs: 'column', sm: 'row' },
          alignItems: { xs: 'start', sm: 'center' },
          flexWrap: 'wrap',
          justifyContent: 'space-between',
        }}
      >
        <Typography level="h2" component="h1">
          {task.name}
        </Typography>
        {isActiveProject(project) && (
          <Dropdown>
            <MenuButton color="secondary" startDecorator={<Add />}>
              Add To Punchlist
            </MenuButton>
            <Menu>
              <MenuItem
                color="secondary"
                onClick={() => {
                  dispatch(openModal(modalContent));
                }}
              >
                Add File
              </MenuItem>
            </Menu>
          </Dropdown>
        )}
      </Box>

      <Tabs
        defaultValue={0}
        value={getDefaultTabValue()}
        onChange={(e, selectedTab) => {
          selectedTab === 0 &&
            navigate(`/projects/${projectId}/tasks/${task.parentTaskId}/punchlist/${task.id}`, { replace: true });
          selectedTab === 2 &&
            navigate(`/projects/${projectId}/tasks/${task.parentTaskId}/punchlist/${task.id}/files`, {
              replace: true,
            });
        }}
        sx={{
          bgcolor: 'transparent',
        }}
      >
        <TabList
          tabFlex={1}
          size="sm"
          sx={{
            pl: { xs: 0, md: 4 },
            justifyContent: 'left',
            [`&& .${tabClasses.root}`]: {
              fontWeight: '600',
              flex: 'initial',
              color: 'text.tertiary',
              [`&.${tabClasses.selected}`]: {
                bgcolor: 'transparent',
                color: 'text.primary',
                '&::after': {
                  height: '2px',
                  bgcolor: 'primary.500',
                },
              },
            },
          }}
        >
          <Tab sx={{ borderRadius: '6px 6px 0 0' }} indicatorInset value={0}>
            Details
          </Tab>
          {!task.parentTaskId && (
            <Tab sx={{ borderRadius: '6px 6px 0 0' }} indicatorInset value={1}>
              Sub Tasks
            </Tab>
          )}
          <Tab sx={{ borderRadius: '6px 6px 0 0' }} indicatorInset value={2}>
            Files
          </Tab>
        </TabList>

        <TabPanel value={0}>
          <PunchlistForm task={task} saveTask={updateTask} users={getUserOptions(users, task.projectId)} />
        </TabPanel>
        <TabPanel value={1}>
          <PunchlistTableComponent rows={task.subTasks ? task.subTasks : []} />
        </TabPanel>
        <TabPanel value={2}>
          <FilesTableComponent rows={(task as TaskWithFiles).files} />
        </TabPanel>
      </Tabs>
    </>
  );
}
