/* eslint-disable jsx-a11y/anchor-is-valid */
import { ArrowDropDown, MoreHorizRounded, WarningRounded } from '@mui/icons-material';
import {
  Badge,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Dropdown,
  FormControl,
  FormLabel,
  IconButton,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  Modal,
  ModalDialog,
  Option,
  Select,
  Typography,
} from '@mui/joy';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Chat, Project, canUserDeleteChat, canUserUpdateChat } from '@builder-bud/common';
import {
  AlertObject,
  selectCurrentUserId,
  showErrorAlert,
  showInfoAlert,
  useAppDispatch,
  useAppSelector,
  useDeleteChatMutation,
} from '@builder-bud/common-ui';

import CircularProgressComponent from '../../components/circular-progress.component';
import DateTimeComponent from '../../components/date-time.component';
import MembersComponent from '../../components/members.component';
import ProjectNameComponent from '../../components/project-name';
import { Order, getComparator } from '../../components/table';
import TableComponent from '../../components/table.component';

export default function ChatTableComponent({
  rows,
  projects = [],
  showProject = false,
  hideSearch = false,
}: {
  rows: Chat[];
  projects?: Project[];
  showProject?: boolean;
  hideSearch?: boolean;
}) {
  const dispatch = useAppDispatch();
  const [deleteChat] = useDeleteChatMutation();

  const currentUserId = useAppSelector(selectCurrentUserId);

  const [order, setOrder] = useState<Order>('');
  const [projectFilter, setProjectFilter] = useState<string[]>([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [rowToDelete, setRowToDelete] = useState<Chat | null>(null);
  const [isDeleting, setIsDeleting] = useState(false);

  const filteredRows = projectFilter.length ? rows.filter((row) => projectFilter.includes(row.projectId)) : rows;

  async function handleDeleteChat() {
    if (rowToDelete) {
      setIsDeleting(true);
      try {
        await deleteChat({ projectId: rowToDelete.projectId, chatId: rowToDelete.id }).unwrap();
        dispatch(showInfoAlert('Successfully deleted Chat'));
      } catch (error) {
        console.log(error);
        dispatch(showErrorAlert(error as AlertObject));
      } finally {
        setRowToDelete(null);
        setIsDeleting(false);
      }
    }
  }

  const renderFilters = () => (
    <FormControl size="sm">
      <FormLabel>Status</FormLabel>
      <Select
        value={projectFilter}
        onChange={(e, newValue) => setProjectFilter(newValue)}
        size="sm"
        placeholder="Filter by project"
        slotProps={{ button: { sx: { whiteSpace: 'nowrap' } } }}
        multiple={true}
      >
        {projects.map((option) => (
          <Option key={option.id} value={option.id}>
            {option.name}
          </Option>
        ))}
      </Select>
    </FormControl>
  );

  const renderTableHead = () => (
    <thead>
      <tr>
        <th style={{ padding: '12px 6px' }}>
          <Link
            underline="none"
            color="primary"
            component="button"
            onClick={() => setOrder(order === '' ? 'asc' : order === 'asc' ? 'desc' : '')}
            fontWeight="lg"
            endDecorator={<ArrowDropDown />}
            sx={{
              '& svg': {
                transition: '0.2s',
                transform: order === '' ? 'rotate(90deg)' : order === 'desc' ? 'rotate(0deg)' : 'rotate(180deg)',
              },
            }}
          >
            Chat
          </Link>
        </th>
        {showProject && <th style={{ padding: '12px 6px' }}>Project name</th>}
        <th style={{ padding: '12px 6px' }}>Members</th>
        <th style={{ padding: '12px 6px' }}>Last message</th>
        <th style={{ width: 48, padding: '12px 6px' }}></th>
      </tr>
    </thead>
  );

  function TableRowMenu({ row }: { row: Chat }) {
    const navigate = useNavigate();

    return (
      <Dropdown>
        <MenuButton
          slots={{ root: IconButton }}
          slotProps={{ root: { variant: 'plain', color: 'neutral', size: 'sm' } }}
        >
          <MoreHorizRounded />
        </MenuButton>
        <Menu size="sm" sx={{ minWidth: 140 }}>
          <MenuItem onClick={() => navigate(`/projects/${row.projectId}/chats/${row.id}/messages`)}>View</MenuItem>
          {currentUserId && canUserUpdateChat(row, currentUserId) && (
            <MenuItem onClick={() => navigate(`/projects/${row.projectId}/chats/${row.id}`)}>Edit</MenuItem>
          )}
          {currentUserId && canUserDeleteChat(row, currentUserId) && (
            <MenuItem
              color="danger"
              onClick={() => {
                setRowToDelete(row);
                setModalOpen(true);
              }}
            >
              Delete
            </MenuItem>
          )}
        </Menu>
      </Dropdown>
    );
  }

  function TableRow({ row }: { row: Chat }) {
    const navigate = useNavigate();

    return (
      <tr key={row.id} style={{ backgroundColor: 'var(--joy-palette-common-white)' }}>
        <td>
          <Link onClick={() => navigate(`/projects/${row.projectId}/chats/${row.id}/messages`)}>
            <Typography level="body-xs">{row.name}</Typography>
            <Badge badgeContent={row.unreadMessageCount} showZero={false} sx={{ marginLeft: 2 }} />
          </Link>
        </td>
        {showProject && (
          <td>
            <Typography>
              <ProjectNameComponent projectId={row.projectId} />
            </Typography>
          </td>
        )}
        <td>
          <MembersComponent userIds={row.userIds} projectId={row.projectId} />
        </td>
        <td>{row.lastMessage && <DateTimeComponent date={row.lastMessage.createdAt} />}</td>
        <td>
          <TableRowMenu row={row} />
        </td>
      </tr>
    );
  }

  if (isDeleting) return <CircularProgressComponent />;

  return (
    <>
      <TableComponent
        renderFilters={renderFilters}
        renderTableHead={renderTableHead}
        TableRow={TableRow}
        rows={filteredRows}
        comparator={getComparator(order, 'name')}
        hideSearch={hideSearch}
      />
      <Modal
        open={modalOpen}
        onClose={() => {
          setRowToDelete(null);
          setModalOpen(false);
        }}
      >
        <ModalDialog variant="outlined" role="alertdialog">
          <DialogTitle>
            <WarningRounded />
            Confirmation
          </DialogTitle>
          <Divider />
          <DialogContent>Are you sure you want to delete this chat?</DialogContent>
          <DialogActions>
            <Button variant="solid" color="danger" onClick={() => handleDeleteChat()}>
              Delete chat
            </Button>
            <Button variant="plain" color="neutral" onClick={() => setModalOpen(false)}>
              Cancel
            </Button>
          </DialogActions>
        </ModalDialog>
      </Modal>
    </>
  );
}
