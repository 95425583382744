import {
  AddCircle,
  ArrowCircleUp,
  Cancel,
  ChatBubble,
  CheckCircle,
  ChevronRightRounded,
  HomeRounded,
  VideoFile,
} from '@mui/icons-material';
import {
  Box,
  Breadcrumbs,
  Card,
  CircularProgress,
  Link,
  Stack,
  Tab,
  TabList,
  TabPanel,
  Tabs,
  Typography,
  tabClasses,
  useTheme,
} from '@mui/joy';
import { useCallback, useEffect, useRef, useState } from 'react';
import { FlatList, TextInput, View } from 'react-native';
import {
  Actions,
  Bubble,
  Composer,
  ComposerProps,
  GiftedChat,
  IMessage,
  Send,
  SendProps,
} from 'react-native-gifted-chat';

import { ChatId, FileId, MessageId, ProjectId, UserId } from '@builder-bud/common';
import {
  AlertObject,
  GiftedChatMessage,
  IMAGE_TYPE,
  VIDEO_TYPE,
  isActiveProject,
  openModal,
  selectCurrentUserId,
  selectGiftedChatMessages,
  setActiveChatId,
  setGiftedChatMessages,
  showErrorAlert,
  useAppDispatch,
  useAppSelector,
  useCreateMessageFileMutation,
  useCreateMessageMutation,
  useDeleteMessageMutation,
  useEditMessageMutation,
  useGetChatQuery,
  useGetMessageFileDownloadUrlMutation,
  useGetMessageFileUploadUrlMutation,
  useGetProjectQuery,
  useLazyGetMessagesQuery,
  useReadMessageMutation,
} from '@builder-bud/common-ui';

import CircularProgressComponent from '../../components/circular-progress.component';
import ErrorLoadingComponent from '../../components/error-loading.component';
import MemberComponent from '../../components/member.component';
import NotFoundComponent from '../../components/not-found.component';
import { useRequiredParams } from '../../utils';
import ChatFileUploadModal from '../files/chat-file-upload.modal';

export default function ChatTableComponent() {
  const { projectId } = useRequiredParams<{ projectId: ProjectId }>();
  const { chatId } = useRequiredParams<{ chatId: ChatId }>();

  const dispatch = useAppDispatch();
  const theme = useTheme();

  const [createMessage] = useCreateMessageMutation();
  const [deleteMessage] = useDeleteMessageMutation();
  const [editMessage] = useEditMessageMutation();
  const [readMessage] = useReadMessageMutation();

  const [getFileUploadUrl] = useGetMessageFileUploadUrlMutation();
  const [createFile] = useCreateMessageFileMutation();
  const [getFileDownloadUrl] = useGetMessageFileDownloadUrlMutation();
  const [triggerGetMessages, getMessagesResult] = useLazyGetMessagesQuery();

  const currentUserId = useAppSelector(selectCurrentUserId);
  const giftedChatMessages = useAppSelector(selectGiftedChatMessages);
  const giftedChatRef = useRef<FlatList<IMessage> | null>(null);
  const textInputRef = useRef<TextInput>(null);
  const [sending, setSending] = useState(false);
  const [messageToEdit, setMessageToEdit] = useState<IMessage | null>(null);
  const [giftedChatMessagesWithImages, setGiftedChatMessagesWithImages] = useState(giftedChatMessages);
  const [file, setFile] = useState<File | null>();

  const uploadChatFileModal = <ChatFileUploadModal setFile={setFile} />;

  const { data: project } = useGetProjectQuery(projectId);
  const {
    data: chat,
    isLoading: isChatLoading,
    isFetching: isChatFetching,
    isError: isChatError,
    error: chatError = {},
  } = useGetChatQuery({ projectId, chatId });

  function createObjectURL(object: Blob | MediaSource) {
    return window.URL ? window.URL.createObjectURL(object) : window.webkitURL.createObjectURL(object);
  }

  const handleUploadFile = useCallback(async () => {
    if (!file) {
      dispatch(showErrorAlert({ message: 'File not found' }));
      return;
    }

    const fileUploadUrlResult = await getFileUploadUrl({ projectId, chatId, contentType: file.type }).unwrap();
    const url = fileUploadUrlResult.url;

    const fileUploadResult = await fetch(url, { method: 'PUT', body: file, headers: { 'Content-Type': file.type } });

    if (fileUploadResult.status !== 200) {
      throw new Error('File upload failed');
    }

    const body = {
      projectId,
      chatId,
      id: fileUploadUrlResult.id,
      name: file.name,
      //description: '',
      contentType: file.type,
      size: file.size,
    };

    return createFile(body);
  }, [chatId, dispatch, projectId, createFile, file, getFileUploadUrl]);

  const onSend = useCallback(
    async (message: GiftedChatMessage) => {
      setSending(true);
      try {
        let fileIds;
        if (file) {
          const fileUploadResponse = await handleUploadFile();
          fileIds = fileUploadResponse ? [fileUploadResponse.data?.id as FileId] : undefined;

          if (file.type.startsWith(IMAGE_TYPE)) {
            message.image = createObjectURL(file);
          }
          if (file.type.startsWith(VIDEO_TYPE)) {
            message.video = createObjectURL(file);
          }
        }

        await createMessage({
          projectId: projectId,
          chatId: chatId,
          content: message.text,
          fileIds,
        });

        setFile(null);

        dispatch(setGiftedChatMessages(GiftedChat.prepend(giftedChatMessages, [message], true)));
      } catch (error) {
        console.log(error);
        //TODO - Need to handle this error and clean up the sent message or show an error
        dispatch(showErrorAlert(error as AlertObject));
      } finally {
        setSending(false);
      }
    },
    [chatId, projectId, createMessage, dispatch, file, giftedChatMessages, handleUploadFile]
  );

  const handleEditMessage = async (message: GiftedChatMessage) => {
    setSending(true);
    try {
      await editMessage({ projectId, chatId, messageId: messageToEdit?._id as MessageId, content: message.text });
      dispatch(
        setGiftedChatMessages(
          giftedChatMessages.map((m) => (m._id === messageToEdit?._id ? { ...m, text: message.text } : m))
        )
      );
      setMessageToEdit(null);
    } catch (error) {
      console.log(error);
      dispatch(showErrorAlert(error as AlertObject));
    } finally {
      setSending(false);
    }
  };

  const handleDeleteMessage = async (message: IMessage) => {
    setSending(true);
    try {
      await deleteMessage({ projectId, chatId, messageId: message._id as MessageId });
      dispatch(setGiftedChatMessages(giftedChatMessages.filter((m) => m._id !== message._id)));
    } catch (error) {
      console.log(error);
      dispatch(showErrorAlert(error as AlertObject));
    } finally {
      setSending(false);
    }
  };

  useEffect(() => {
    dispatch(setActiveChatId(chatId));
    triggerGetMessages({ projectId: projectId, chatId: chatId });

    return () => {
      dispatch(setActiveChatId(null));
      dispatch(setGiftedChatMessages([]));
    };
  }, [dispatch, chatId, projectId, triggerGetMessages]);

  useEffect(() => {
    //When an upate occurs to the message list scroll to the end
    giftedChatRef.current?.scrollToEnd();

    //When new messages come into giftedChatMessage fetch any image download urls
    const giftedChatMessagesWithImages = Promise.all(
      giftedChatMessages.map(async (m) => {
        const message = { ...m };
        if (message.files && message.files[0]) {
          const fileDownloadUrlResult = await getFileDownloadUrl({
            projectId: projectId,
            chatId: chatId,
            fileId: message.files[0].id,
          });

          if (message.files[0].contentType.startsWith(IMAGE_TYPE)) {
            message.image = fileDownloadUrlResult.data?.url;
          }
          if (message.files[0].contentType.startsWith(VIDEO_TYPE)) {
            message.video = fileDownloadUrlResult.data?.url;
          }
        }

        if (message.user._id !== currentUserId && !message.isRead) {
          await readMessage({
            projectId: projectId,
            chatId: chatId,
            messageId: message._id as MessageId,
          });
          message.isRead = true;
        }
        return message;
      })
    );

    giftedChatMessagesWithImages.then((result) => {
      setGiftedChatMessagesWithImages(result);
    });
  }, [chatId, projectId, getFileDownloadUrl, giftedChatMessages, currentUserId, readMessage]);

  //Gifted chat messages don't display initially after loading
  //https://github.com/FaridSafi/react-native-gifted-chat/issues/2448
  useEffect(() => {
    const gcLoadingContaineEl = document.querySelectorAll('[data-testid="GC_LOADING_CONTAINER"]')[0] as HTMLElement;
    if (gcLoadingContaineEl) {
      gcLoadingContaineEl.style.display = 'none';
      setTimeout(() => {
        gcLoadingContaineEl.style.display = 'flex';
      }, 500);
    }
  });

  //Had to move this to a useEffect otherwise the TextInput gets immediately overwritten or doesn't render
  useEffect(() => {
    if (textInputRef.current && messageToEdit) {
      (textInputRef.current as unknown as HTMLTextAreaElement).value = messageToEdit.text;
    }
  }, [messageToEdit]);

  const isLoading = isChatLoading || getMessagesResult.isLoading;
  const isFetching = isChatFetching || getMessagesResult.isFetching;
  const isError = isChatError || getMessagesResult.isError;
  const error = isChatError ? chatError : getMessagesResult.isError ? getMessagesResult.error : {};

  if (isLoading || isFetching) return <CircularProgressComponent />;
  if (isError) return <ErrorLoadingComponent error={error} />;
  if (!chat) return <NotFoundComponent />;

  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Breadcrumbs
          size="sm"
          aria-label="breadcrumbs"
          separator={<ChevronRightRounded fontSize="small" />}
          sx={{ pl: 0 }}
        >
          <Link underline="none" color="neutral" href="/" aria-label="Home">
            <HomeRounded />
          </Link>
          <Link underline="hover" color="neutral" href="/projects" fontSize={12} fontWeight={500}>
            Projects
          </Link>
          <Link underline="hover" color="neutral" href={`/projects/${chat.projectId}`} fontSize={12} fontWeight={500}>
            {chat.projectId}
          </Link>
          <Link
            underline="hover"
            color="neutral"
            href={`/projects/${chat.projectId}/chats`}
            fontSize={12}
            fontWeight={500}
          >
            Chats
          </Link>
          <Typography color="primary" fontWeight={500} fontSize={12}>
            {chat.id}
          </Typography>
        </Breadcrumbs>
      </Box>
      <Box
        sx={{
          display: 'flex',
          mb: 1,
          gap: 1,
          flexDirection: { xs: 'column', sm: 'row' },
          alignItems: { xs: 'start', sm: 'center' },
          flexWrap: 'wrap',
          justifyContent: 'space-between',
        }}
      >
        <Typography level="h2" component="h1">
          {chat.name}
        </Typography>
      </Box>

      <Tabs
        defaultValue={0}
        value={0}
        sx={{
          bgcolor: 'transparent',
          display: 'flex',
          flex: 1,
        }}
      >
        <TabList
          tabFlex={1}
          size="sm"
          sx={{
            pl: { xs: 0, md: 4 },
            justifyContent: 'left',
            [`&& .${tabClasses.root}`]: {
              fontWeight: '600',
              flex: 'initial',
              color: 'text.tertiary',
              [`&.${tabClasses.selected}`]: {
                bgcolor: 'transparent',
                color: 'text.primary',
                '&::after': {
                  height: '2px',
                  bgcolor: 'primary.500',
                },
              },
            },
          }}
        >
          <Tab sx={{ borderRadius: '6px 6px 0 0' }} indicatorInset value={0}>
            Messages
          </Tab>
        </TabList>

        <TabPanel value={0} sx={{ display: 'flex', flex: 1, justifyContent: 'center' }}>
          <Card sx={{ display: 'flex', flex: 1, flexGrow: 1, maxWidth: 800 }}>
            <View style={{ flex: 1, flexGrow: 1 }}>
              <GiftedChat
                messageContainerRef={giftedChatRef}
                textInputRef={textInputRef}
                messages={giftedChatMessagesWithImages}
                onSend={(messages) =>
                  messageToEdit
                    ? handleEditMessage(messages[0] as GiftedChatMessage)
                    : onSend(messages[0] as GiftedChatMessage)
                }
                user={{
                  _id: currentUserId ? currentUserId : '',
                }}
                inverted={false}
                messagesContainerStyle={{ paddingBottom: 16 }}
                renderChatEmpty={() => {
                  return (
                    <Stack flex={1} justifyContent="center" alignItems="center" style={{ transform: 'scaleY(-1)' }}>
                      <Typography fontSize={32}>
                        <ChatBubble fontSize="inherit" />
                      </Typography>
                      <Typography>No messages yet</Typography>
                    </Stack>
                  );
                }}
                renderBubble={(props) => {
                  return (
                    <Bubble
                      {...props}
                      textStyle={{
                        left: {
                          color: theme.vars.palette.common.white,
                        },
                        right: {
                          color: theme.vars.palette.common.white,
                        },
                      }}
                      wrapperStyle={{
                        left: {
                          backgroundColor: theme.vars.palette.secondary[500],
                        },
                        right: {
                          backgroundColor: theme.vars.palette.primary[500],
                        },
                      }}
                    />
                  );
                }}
                renderActions={(props) => {
                  return isActiveProject(project) ? (
                    <>
                      {messageToEdit ? (
                        //Had to duplicate the entire Actions object and add a key to get this to render properly for both cases
                        <Actions
                          {...props}
                          key="cancel"
                          onPressActionButton={() => {
                            setMessageToEdit(null);
                          }}
                          icon={() => (
                            <Typography fontSize={24}>
                              <Cancel fontSize="inherit" color="warning" />
                            </Typography>
                          )}
                        />
                      ) : (
                        <Actions
                          {...props}
                          key="add"
                          onPressActionButton={() => {
                            dispatch(openModal(uploadChatFileModal));
                          }}
                          icon={() => (
                            <Typography fontSize={24}>
                              <AddCircle fontSize="inherit" color="warning" />
                            </Typography>
                          )}
                        />
                      )}
                      {file && (
                        <View style={{ padding: 8, justifyContent: 'center' }}>
                          {file.type.startsWith(IMAGE_TYPE) && (
                            <img src={createObjectURL(file)} height={48} width={32} alt="" />
                          )}
                          {file.type.startsWith(VIDEO_TYPE) && (
                            <Typography fontSize={24}>
                              <VideoFile fontSize="inherit" color="warning" />
                            </Typography>
                          )}
                          <Typography
                            fontSize={16}
                            onClick={() => setFile(null)}
                            sx={{
                              position: 'absolute',
                              top: 4,
                              right: -4,
                            }}
                          >
                            <Cancel fontSize="inherit" color="info" />
                          </Typography>
                        </View>
                      )}
                    </>
                  ) : (
                    // eslint-disable-next-line react/jsx-no-useless-fragment
                    <></>
                  );
                }}
                renderComposer={(
                  props: ComposerProps & {
                    onSend: SendProps<IMessage>['onSend'];
                    text: SendProps<IMessage>['text'];
                  }
                ) => {
                  return isActiveProject(project) ? (
                    <Composer
                      {...props}
                      textInputProps={{
                        ...props.textInputProps,
                        // for enabling the Return key to send a message
                        blurOnSubmit: true,
                        onSubmitEditing: () => {
                          if (props.text && props.onSend) {
                            props.onSend({ text: props.text.trim() }, true);
                          }
                        },
                      }}
                    />
                  ) : (
                    // eslint-disable-next-line react/jsx-no-useless-fragment
                    <></>
                  );
                }}
                alwaysShowSend //Needed to allow just sending an image
                renderSend={(props) => {
                  return isActiveProject(project) ? (
                    <Stack style={{ marginRight: 8 }}>
                      {sending ? (
                        <Typography fontSize={24}>
                          <CircularProgress size="sm" color="warning" />
                        </Typography>
                      ) : (
                        //Using a blank space to make the send button active when there is only an image
                        <Send {...props} text={file && props.text === '' ? ' ' : props.text}>
                          <Typography fontSize={24}>
                            {messageToEdit ? (
                              <CheckCircle fontSize="inherit" color="warning" />
                            ) : (
                              <ArrowCircleUp fontSize="inherit" color="warning" />
                            )}
                          </Typography>
                        </Send>
                      )}
                    </Stack>
                  ) : (
                    // eslint-disable-next-line react/jsx-no-useless-fragment
                    <></>
                  );
                }}
                renderAvatar={(props) => {
                  return <MemberComponent userId={props.currentMessage?.user._id as UserId} projectId={projectId} />;
                }}
                renderMessageImage={(props) => {
                  return (
                    <Stack sx={{ borderRadius: '13px', padding: 1 }}>
                      {props.currentMessage && (
                        <img
                          src={props.currentMessage.image}
                          width={300}
                          height={200}
                          style={{ borderRadius: '13px', objectFit: 'cover', cursor: 'pointer' }}
                          alt=""
                          onClick={() =>
                            dispatch(
                              openModal(
                                <img
                                  src={props.currentMessage?.image}
                                  style={{ objectFit: 'contain', alignSelf: 'center' }}
                                  alt=""
                                />
                              )
                            )
                          }
                        />
                      )}
                    </Stack>
                  );
                }}
                renderMessageVideo={(props) => {
                  return (
                    <Stack sx={{ borderRadius: '13px', padding: 1 }}>
                      {props.currentMessage && (
                        <video
                          controls
                          width={300}
                          height={200}
                          style={{ borderRadius: '13px', objectFit: 'contain', cursor: 'pointer' }}
                        >
                          <source src={props.currentMessage?.video} type="video/mp4"></source>
                        </video>
                      )}
                    </Stack>
                  );
                }}
                onLongPress={(context, message) => {
                  const options = ['Copy Message Text', 'Cancel'];

                  if (isActiveProject(project) && message.user._id === currentUserId) {
                    options.unshift('Edit Message Text', 'Delete Message');
                  }

                  const cancelButtonIndex = options.length - 1;
                  context.actionSheet().showActionSheetWithOptions(
                    {
                      options,
                      cancelButtonIndex,
                    },
                    async (buttonIndex: any) => {
                      switch (buttonIndex) {
                        case 0:
                          if (isActiveProject(project) && message.user._id === currentUserId) {
                            setMessageToEdit(message);
                          } else {
                            navigator.clipboard.writeText(message.text);
                          }
                          break;
                        case 1:
                          handleDeleteMessage(message);
                          break;
                        case 2:
                          navigator.clipboard.writeText(message.text);
                          break;
                      }
                    }
                  );
                }}
              />
            </View>
          </Card>
        </TabPanel>
      </Tabs>
    </>
  );
}
