import { BaseResponse, DateWithoutTime, WithTimestamps } from './common';
import { FileWithUrl } from './file';
import { NotificationType } from './notification';
import { UserId } from './user';

export const PROJECT_ID_PREFIX = 'project_';

export type ProjectId = `${typeof PROJECT_ID_PREFIX}${string}`;

export function isProjectId(id: string): id is ProjectId {
  return id.startsWith(PROJECT_ID_PREFIX);
}

export function isProject(data: unknown): data is Project {
  return (
    typeof data === 'object' &&
    data !== null &&
    'id' in data &&
    typeof (data as Project).id === 'string' &&
    isProjectId((data as Project).id)
  );
}

export type Project = WithTimestamps<{
  id: ProjectId;
  name: string;
  description?: string;
  notes?: string;
  addressLine1: string;
  addressLine2?: string;
  city: string;
  stateCode: string;
  zipCode: string;
  status: ProjectStatus;
  displayAddress?: string[]; // may be populated by an interceptor
  coverImageUrl?: string;
  coverImageThumbnailUrl?: string;
  permit?: string;
  homeDetails?: string;
  startDate?: DateWithoutTime;
  estimatedEndDate?: DateWithoutTime;
  userIds: UserId[];
  createdByUserId: UserId;
}>;

export type ProjectWithFiles = Project & {
  files: FileWithUrl[];
};

export type ProjectUser = {
  userId: UserId;
  projectId: ProjectId;
  projectRole: UserProjectRole;
  invitedByUserId?: UserId;
};

export type ReferencedProject = Pick<Project, 'id' | 'name'>;

export type CreateProjectRequest = {
  name: string;
  description?: string;
  notes?: string;
  addressLine1: string;
  addressLine2?: string;
  city: string;
  stateCode: string;
  zipCode: string;
  permit?: string;
  homeDetails?: string;
  startDate?: DateWithoutTime;
};

export type UpdateProjectRequest = {
  name?: string;
  description?: string | null;
  notes?: string | null;
  addressLine1?: string;
  addressLine2?: string | null;
  city?: string;
  stateCode?: string;
  zipCode?: string;
  status?: ProjectStatus;
  permit?: string | null;
  homeDetails?: string | null;
  startDate?: DateWithoutTime;
};

export type CreateProjectResponse = BaseResponse<Project>;

export type GetProjectResponse = BaseResponse<ProjectWithFiles>;

export type GetProjectsResponse = BaseResponse<Project[]>;

export type UpdateProjectResponse = BaseResponse<Project>;

export type DeleteProjectResponse = BaseResponse<void>;

export enum ProjectStatus {
  NotStarted = 'NOT_STARTED',
  InProgress = 'IN_PROGRESS',
  Paused = 'PAUSED',
  Completed = 'COMPLETED',
  Archived = 'ARCHIVED',
}

export const PROJECT_STATUS_LABELS: { [key in ProjectStatus]: string } = {
  [ProjectStatus.NotStarted]: 'Not started',
  [ProjectStatus.InProgress]: 'In progress',
  [ProjectStatus.Paused]: 'Paused',
  [ProjectStatus.Completed]: 'Completed',
  [ProjectStatus.Archived]: 'Archived',
};

export enum UserProjectRole {
  Homeowner = 'HOMEOWNER',
  Contractor = 'CONTRACTOR',
  Subcontractor = 'SUBCONTRACTOR',
  ProjectManager = 'PROJECT_MANAGER',
  InteriorDesigner = 'INTERIOR_DESIGNER',
}

export const USER_PROJECT_ROLE_LABELS: { [key in UserProjectRole]: string } = {
  [UserProjectRole.Homeowner]: 'Homeowner',
  [UserProjectRole.Contractor]: 'Contractor',
  [UserProjectRole.Subcontractor]: 'Subcontractor',
  [UserProjectRole.ProjectManager]: 'Project Manager',
  [UserProjectRole.InteriorDesigner]: 'Interior Designer',
};

export enum ProjectType {
  ADU = 'ADU',
  FullGutRemodel = 'FULL_GUT_REMODEL',
  Bathroom = 'BATHROOM',
  Kitchen = 'KITCHEN',
  NewBuild = 'NEW_BUILD',
  Other = 'OTHER',
}

export const PROJECT_TYPE_LABELS: { [key in ProjectType]: string } = {
  [ProjectType.ADU]: 'ADU',
  [ProjectType.FullGutRemodel]: 'Full gut remodel',
  [ProjectType.Bathroom]: 'Bathroom',
  [ProjectType.Kitchen]: 'Kitchen',
  [ProjectType.NewBuild]: 'New build',
  [ProjectType.Other]: 'Other',
};

export type ProjectStatusChangeNotification = {
  title: string;
  body: string;
  data: {
    type: NotificationType.ProjectStatusChange;
    projectId: ProjectId;
    projectName: string;
    projectStatus: ProjectStatus;
  };
};

export type ProjectInvitationReceivedNotification = {
  title: string;
  body: string;
  data: {
    type: NotificationType.ProjectInvitationReceived;
    projectId: ProjectId;
    projectName: string;
    projectRole: string;
    createdByUserName: string;
    createdByUserEmail: string;
    createdByUserPhone: string;
  };
};

export type ProjectInvitationAcceptedNotification = {
  title: string;
  body: string;
  data: {
    type: NotificationType.ProjectInvitationAccepted;
    projectId: ProjectId;
    projectName: string;
    projectRole: string;
    invitedUserName: string;
    invitedUserEmail: string;
    invitedUserPhone: string;
  };
};

export type ProjectOnMyWayNotification = {
  title: string;
  body: string;
  data: {
    type: NotificationType.ProjectOnMyWay;
    projectId: ProjectId;
  };
};

// idea is we union this when we have more types
export type SendProjectNotificationRequest = {
  type: ProjectNotificationType.OnMyWay;
  timeframe: string; // leaving loose for experimentation, will tighten up later
};

export enum ProjectNotificationType {
  OnMyWay = 'ON_MY_WAY',
}

export type SendProjectNotificationResponse = BaseResponse<void>;

export type GetProjectTagsResponse = BaseResponse<string[]>;

export type RemoveUserFromProjectResponse = BaseResponse<void>;

export function isNonHomeownerProjectRole(role?: UserProjectRole | null): boolean {
  return Boolean(role && role !== UserProjectRole.Homeowner);
}

export function isSubUserProjectRole(role: UserProjectRole): boolean {
  return ![UserProjectRole.Contractor, UserProjectRole.Homeowner].includes(role);
}
