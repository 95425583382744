import { ChevronRightRounded, HomeRounded } from '@mui/icons-material';
import { Box, Breadcrumbs, Link, Typography } from '@mui/joy';

import { ProjectId } from '@builder-bud/common';
import { getUserOptions, useCreateDailyLogMutation, useGetUsersQuery } from '@builder-bud/common-ui';

import CircularProgressComponent from '../../components/circular-progress.component';
import ErrorLoadingComponent from '../../components/error-loading.component';
import { useRequiredParams } from '../../utils';
import DailyLogForm from './daily-log.form';

export default function DailyLogCreateScreen() {
  const { projectId } = useRequiredParams<{ projectId: ProjectId }>();
  const [createDailyLog] = useCreateDailyLogMutation();

  const { data: users = [], isLoading, isFetching, isError, error } = useGetUsersQuery();

  if (isLoading || isFetching) return <CircularProgressComponent />;
  if (isError) return <ErrorLoadingComponent error={error} />;

  const dailyLog = {
    projectId,
  };

  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Breadcrumbs
          size="sm"
          aria-label="breadcrumbs"
          separator={<ChevronRightRounded fontSize="small" />}
          sx={{ pl: 0 }}
        >
          <Link underline="none" color="neutral" href="/" aria-label="Home">
            <HomeRounded />
          </Link>
          <Link underline="hover" color="neutral" href="/projects" fontSize={12} fontWeight={500}>
            Projects
          </Link>
          <Link
            underline="hover"
            color="neutral"
            href={`/projects/${dailyLog.projectId}`}
            fontSize={12}
            fontWeight={500}
          >
            {dailyLog.projectId}
          </Link>
          <Link
            underline="hover"
            color="neutral"
            href={`/projects/${dailyLog.projectId}/dailyLogs`}
            fontSize={12}
            fontWeight={500}
          >
            Daily Logs
          </Link>
          <Typography color="primary" fontWeight={500} fontSize={12}>
            New Daily Log
          </Typography>
        </Breadcrumbs>
      </Box>
      <Box
        sx={{
          display: 'flex',
          mb: 1,
          gap: 1,
          flexDirection: { xs: 'column', sm: 'row' },
          alignItems: { xs: 'start', sm: 'center' },
          flexWrap: 'wrap',
          justifyContent: 'space-between',
        }}
      >
        <Typography level="h2" component="h1">
          New Daily Log
        </Typography>
        <Typography level="body-sm" color="warning">
          Save daily log first to add files
        </Typography>
      </Box>

      <DailyLogForm
        dailyLog={dailyLog}
        saveDailyLog={createDailyLog}
        users={getUserOptions(users, dailyLog.projectId)}
      />
    </>
  );
}
