import { CheckRounded, KeyboardArrowDownRounded } from '@mui/icons-material';
import { Box, Step, StepIndicator, Stepper, Typography, useTheme } from '@mui/joy';
import { Theme } from '@mui/joy/styles';
import { useState } from 'react';

import { AppRole } from '@builder-bud/common';
import { OnboardingStep, UnauthenticatedSteps } from '@builder-bud/common-ui';

import BuilderBudIcon from '../../components/builderbud-icon.component';
import CreateAccountStep from './create-account.step';
import SelectAccountTypeStep from './select-account-type.step';

export default function OnboardingScreen() {
  const styles = makeStyle(useTheme());
  const [activeStep, setActiveStep] = useState<UnauthenticatedSteps>(UnauthenticatedSteps.selectAccountType);
  const [appRole, setAppRole] = useState<AppRole>(AppRole.Homeowner);

  const steps = [
    { index: UnauthenticatedSteps.selectAccountType, label: 'Account Type' },
    { index: UnauthenticatedSteps.createAccount, label: 'Create Account' },
    { index: UnauthenticatedSteps.ellipsis, label: '...' },
  ];

  const renderStep = (step: OnboardingStep) => {
    return (
      <Step
        key={step.index}
        active={activeStep === step.index ? true : false}
        completed={activeStep > step.index ? true : false}
        orientation="vertical"
        indicator={
          <StepIndicator color="primary" variant={activeStep >= step.index ? 'solid' : 'outlined'}>
            {activeStep === step.index && <KeyboardArrowDownRounded sx={styles.stepIcon} />}
            {activeStep > step.index && <CheckRounded sx={styles.stepIcon} />}
          </StepIndicator>
        }
        sx={{ textAlign: 'center' }}
      >
        {step.label}
      </Step>
    );
  };

  return (
    <Box>
      <Box sx={styles.leftContainer} />
      <Box sx={styles.rightContainer}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            minHeight: '100dvh',
            width: '100%',
            px: 2,
          }}
        >
          <Box
            component="header"
            sx={{
              py: 2,
              gap: 2,
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Box sx={{ gap: 2, display: 'flex', alignItems: 'center' }}>
              <BuilderBudIcon />
              <Typography level="title-md">Welcome to BuilderBud</Typography>
            </Box>
            <Typography level="title-md">Construction made easy</Typography>
          </Box>
          <Box component="main" sx={styles.onboardingContainer}>
            <Stepper size="md">{steps.map((step) => renderStep(step))}</Stepper>
            {activeStep === UnauthenticatedSteps.selectAccountType && (
              <SelectAccountTypeStep setStep={setActiveStep} setAppRole={setAppRole} />
            )}
            {activeStep === UnauthenticatedSteps.createAccount && <CreateAccountStep appRole={appRole} />}
          </Box>

          <Box component="footer" sx={{ py: 2 }}>
            <Typography level="body-xs" textAlign="center">
              © Builder Bud {new Date().getFullYear()}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

const makeStyle = (theme: Theme) => {
  return {
    leftContainer: {
      position: 'fixed',
      left: 0,
      top: 0,
      bottom: 0,
      right: { xs: 0, md: '50vw' },
      transition: 'background-image var(--Transition-duration), left var(--Transition-duration) !important',
      transitionDelay: 'calc(var(--Transition-duration) + 0.1s)',
      backgroundColor: 'var(--joy-palette-primary-500)',
      backgroundSize: 'contain',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      backgroundImage: 'url(../../assets/splash-screen.png)',
    },

    rightContainer: {
      position: 'fixed',
      right: 0,
      top: 0,
      bottom: 0,
      left: { xs: 0, md: '50vw' },
      transition: 'width var(--Transition-duration)',
      transitionDelay: 'calc(var(--Transition-duration) + 0.1s)',
      zIndex: 1,
      display: 'flex',
      justifyContent: 'flex-end',
      backgroundColor: 'var(--joy-palette-common-white)',
    },

    onboardingContainer: {
      my: 'auto',
      py: 2,
      pb: 5,
      display: 'flex',
      flexDirection: 'column',
      gap: 2,
      width: 600,
      maxWidth: '100%',
      mx: 'auto',
      borderRadius: 'sm',
    },

    stepIcon: {
      color: 'var(--joy-palette-common-white)',
    },
  };
};
