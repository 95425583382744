import { BaseResponse, DateWithoutTime, WithTimestamps } from './common';
import { FileWithUrl } from './file';
import { NotificationType } from './notification';
import { ProjectId } from './project';
import { UserId } from './user';

export const CONTRACT_ID_PREFIX = 'contract_';
export type ContractId = `${typeof CONTRACT_ID_PREFIX}${string}`;

export function isContractId(id: string): id is ContractId {
  return id.startsWith(CONTRACT_ID_PREFIX);
}

export function isContractLineItemId(id: string): id is ContractLineItemId {
  return id.startsWith(CONTRACT_LINE_ITEM_ID_PREFIX);
}

export function isContract(data: unknown): data is Contract {
  return (
    typeof data === 'object' &&
    data !== null &&
    'id' in data &&
    typeof (data as Contract).id === 'string' &&
    isContractId((data as Contract).id)
  );
}

export const CONTRACT_LINE_ITEM_ID_PREFIX = 'cntli_';
export type ContractLineItemId = `${typeof CONTRACT_LINE_ITEM_ID_PREFIX}${string}`;

export function isContractLineItem(data: unknown): data is ContractLineItem {
  return (
    typeof data === 'object' &&
    data !== null &&
    'id' in data &&
    typeof (data as ContractLineItem).id === 'string' &&
    isContractLineItemId((data as ContractLineItem).id)
  );
}

export type Contract = WithTimestamps<{
  id: ContractId;
  projectId: ProjectId;
  type: ContractType;
  name: string;
  description?: string;
  reference?: string;
  terms?: string;
  invoiceDate?: DateWithoutTime;
  dueDate?: DateWithoutTime;
  status: ContractStatus;
  signatureRequired: boolean;
  signature?: string; // base64 encoded image // separate endpoint for homeowner to sign?
  approvedByUserId?: UserId;
  approvedAt?: string;
  createdByUserId: UserId;
  lineItems?: ContractLineItem[];
}>;

export type ContractWithFiles = Contract & {
  files: FileWithUrl[];
};

export enum ContractStatus {
  Draft = 'DRAFT',
  Published = 'PUBLISHED',
  Approved = 'APPROVED',
  Paid = 'PAID',
  Declined = 'DECLINED',
}

export const CONTRACT_STATUS_LABELS: Record<ContractStatus, string> = {
  [ContractStatus.Draft]: 'Draft',
  [ContractStatus.Published]: 'Awaiting Approval',
  [ContractStatus.Approved]: 'Approved',
  [ContractStatus.Paid]: 'Paid',
  [ContractStatus.Declined]: 'Declined',
};

export enum ContractType {
  Estimate = 'ESTIMATE',
  PaymentSchedule = 'PAYMENT_SCHEDULE',
  Contract = 'CONTRACT',
  ChangeOrder = 'CHANGE_ORDER',
  ProgressInvoice = 'PROGRESS_INVOICE',
  Invoice = 'INVOICE',
}

export const CONTRACT_TYPE_LABELS: Record<ContractType, string> = {
  [ContractType.Estimate]: 'Estimate',
  [ContractType.PaymentSchedule]: 'Payment Schedule',
  [ContractType.Contract]: 'Contract',
  [ContractType.ChangeOrder]: 'Change Order',
  [ContractType.ProgressInvoice]: 'Progress Invoice',
  [ContractType.Invoice]: 'Invoice',
};

export type CreateContractRequest = {
  type: ContractType;
  name: string;
  description?: string;
  reference?: string;
  terms?: string;
  invoiceDate?: DateWithoutTime;
  dueDate?: DateWithoutTime;
  status?: ContractStatus;
  signatureRequired?: boolean;
};

export type CreateContractResponse = BaseResponse<Contract>;

export type GetContractResponse = BaseResponse<ContractWithFiles>;

export type GetContractsResponse = BaseResponse<Contract[]>;

export type UpdateContractRequest = {
  name?: string;
  description?: string | null;
  reference?: string | null;
  terms?: string | null;
  invoiceDate?: DateWithoutTime | null;
  dueDate?: DateWithoutTime | null;
  status?: ContractStatus;
  signatureRequired?: boolean;
};

export type UpdateContractResponse = BaseResponse<Contract>;

export type ApproveContractRequest = {
  signature?: string;
};

export type ApproveContractResponse = BaseResponse<Contract>;

export type DeleteContractResponse = BaseResponse<void>;

export type ContractLineItem = WithTimestamps<{
  id: ContractLineItemId;
  contractId: ContractId;
  name: string;
  description?: string;
  date?: DateWithoutTime;
  quantity: number;
  margin?: number; // 1.5 = 50% // only returned for createdByUserId
  cost?: number; // only returned for createdByUserId
  amount?: number;
  order: number;
  createdByUserId: UserId;
  calculatedRate: number; // cost * margin
  calculatedAmount: number; // quantity * rate
}>;

export type CreateContractLineItemRequest = {
  name: string;
  description?: string;
  date?: DateWithoutTime;
  quantity: number;
  margin: number;
  amount?: number;
  cost: number;
};

export type CreateContractLineItemResponse = BaseResponse<ContractLineItem>;

export type GetContractLineItemResponse = BaseResponse<ContractLineItem>;

export type GetContractLineItemsResponse = BaseResponse<ContractLineItem[]>;

export type UpdateContractLineItemRequest = {
  name?: string;
  description?: string | null;
  date?: DateWithoutTime | null;
  quantity?: number;
  rate?: number;
  amount?: number | null;
  order?: number | null;
};

export type UpdateContractLineItemResponse = BaseResponse<ContractLineItem>;

export type DeleteContractLineItemResponse = BaseResponse<void>;

export type ContractPublishedNotification = {
  title: string;
  body: string;
  data: {
    type: NotificationType.ContractPublished;
    projectId: ProjectId;
    contractId: ContractId;
    projectName: string;
    contractName: string;
    publishedByUserName: string;
  };
};

export type ContractApprovedNotification = {
  title: string;
  body: string;
  data: {
    type: NotificationType.ContractApproved;
    projectId: ProjectId;
    contractId: ContractId;
    projectName: string;
    contractName: string;
    approvedByUserName: string;
  };
};
