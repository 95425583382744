import { signOut } from '@firebase/auth';
import {
  AccountCircleOutlined,
  AccountTreeOutlined,
  CalendarMonthOutlined,
  CalendarMonthRounded,
  CalendarTodayOutlined,
  CloseRounded,
  FolderOutlined,
  FolderRounded,
  HomeRounded,
  LogoutRounded,
  MessageOutlined,
  QuestionAnswerRounded,
  SearchRounded,
  SettingsRounded,
  SmartphoneRounded,
  StorageRounded,
  SupportRounded,
  WorkOutline,
} from '@mui/icons-material';
import {
  Badge,
  Box,
  CircularProgress,
  Divider,
  Dropdown,
  GlobalStyles,
  IconButton,
  Input,
  List,
  ListDivider,
  ListItem,
  ListItemButton,
  ListItemContent,
  ListItemDecorator,
  Menu,
  MenuItem,
  Sheet,
  Stack,
  Typography,
  listItemButtonClasses,
} from '@mui/joy';
import { useEffect, useState } from 'react';
import { matchPath, useLocation, useNavigate } from 'react-router-dom';
import { useDebounce } from 'use-debounce';

import {
  ContractSearchResult,
  DailyLogSearchResult,
  EventSearchResult,
  MessageSearchResult,
  ProjectSearchResult,
  SearchResults,
  TaskSearchResult,
  UserSearchResult,
} from '@builder-bud/common';
import {
  AlertObject,
  api,
  getInvitationsBadge,
  getUnreadMessagesBadge,
  showErrorAlert,
  useGetMeChatsQuery,
  useGetMeInvitationsQuery,
  useLazySearchQuery,
} from '@builder-bud/common-ui';
import { selectFCMToken, useAppDispatch, useAppSelector, useDeleteDeviceTokenMutation } from '@builder-bud/common-ui';

import { auth } from '../modules/login/firebase';
import { closeSidebar } from '../utils';
import AvatarComponent from './avatar.component';
import BuilderBudIcon from './builderbud-icon.component';

export default function Sidebar() {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const [deleteDeviceToken] = useDeleteDeviceTokenMutation();
  const [triggerLazySearch] = useLazySearchQuery();

  const [search, setSearch] = useState('');
  const [debouncedSearch] = useDebounce(search, 1000);
  const [searching, setSearching] = useState(false);
  const [searchResults, setSearchResults] = useState<SearchResults | null>(null);

  const fcmToken = useAppSelector(selectFCMToken);
  const me = useAppSelector((state) => state.auth?.me);

  const { data: chats } = useGetMeChatsQuery();
  const chatsBadge = getUnreadMessagesBadge(chats);

  const { data: invitations } = useGetMeInvitationsQuery();
  const invitationsBadge = getInvitationsBadge(invitations?.received);

  function truncate(value?: string): string {
    return value ? (value.length > 18 ? `${value.slice(0, 18)} ...` : value) : '';
  }

  //Hides the debounce to look like part of the search request
  useEffect(() => {
    if (search === '') {
      setSearchResults(null);
    } else {
      setSearching(true);
    }
  }, [search]);

  useEffect(() => {
    async function triggerSearch() {
      if (debouncedSearch === '') {
        return;
      }

      setSearching(true);
      try {
        const searchResults = await triggerLazySearch({ q: debouncedSearch });
        searchResults.data && setSearchResults(searchResults.data);
      } catch (error) {
        console.log(error);
        dispatch(showErrorAlert(error as AlertObject));
      } finally {
        setSearching(false);
      }
    }

    triggerSearch();
  }, [debouncedSearch, dispatch, triggerLazySearch]);

  const firstName = truncate(me?.firstName);
  const lastName = truncate(me?.lastName);
  const email = truncate(me?.email);

  const noSearchResults =
    searchResults &&
    'users' in searchResults &&
    searchResults.users.length === 0 &&
    'projects' in searchResults &&
    searchResults.projects.length === 0 &&
    'tasks' in searchResults &&
    searchResults.tasks.length === 0 &&
    'events' in searchResults &&
    searchResults.events.length === 0 &&
    'dailyLogs' in searchResults &&
    searchResults.dailyLogs.length === 0 &&
    'contracts' in searchResults &&
    searchResults.contracts.length === 0 &&
    'messages' in searchResults &&
    searchResults.messages.length === 0;

  return (
    <Sheet
      sx={{
        position: { xs: 'fixed', md: 'sticky' },
        transform: {
          xs: 'translateX(calc(100% * (var(--SideNavigation-slideIn, 0) - 1)))',
          md: 'none',
        },
        transition: 'transform 0.4s, width 0.4s',
        zIndex: 10000,
        height: '100dvh',
        width: 'var(--Sidebar-width)',
        top: 0,
        p: 2,
        flexShrink: 0,
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
      }}
    >
      <GlobalStyles
        styles={(theme) => ({
          ':root': {
            '--Sidebar-width': '220px',
            [theme.breakpoints.up('lg')]: {
              '--Sidebar-width': '240px',
            },
          },
        })}
      />
      <Box
        sx={{
          position: 'fixed',
          zIndex: 9998,
          top: 0,
          left: 0,
          width: '100vw',
          height: '100vh',
          opacity: 'var(--SideNavigation-slideIn)',
          backgroundColor: 'var(--joy-palette-background-backdrop)',
          transition: 'opacity 0.4s',
          transform: {
            xs: 'translateX(calc(100% * (var(--SideNavigation-slideIn, 0) - 1) + var(--SideNavigation-slideIn, 0) * var(--Sidebar-width, 0px)))',
            lg: 'translateX(-100%)',
          },
        }}
        onClick={() => closeSidebar()}
      />
      <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
        <BuilderBudIcon />
        <Typography level="title-lg">BuilderBud</Typography>
      </Box>
      <Dropdown>
        <Input
          size="sm"
          startDecorator={<SearchRounded />}
          endDecorator={
            searching ? (
              <CircularProgress size="sm" />
            ) : searchResults ? (
              <CloseRounded
                onClick={() => {
                  setSearch('');
                }}
              />
            ) : null
          }
          placeholder="Search"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
        <Menu
          sx={{
            zIndex: 100001,
            top: '100px !important',
            left: '16px !important',
            maxHeight: '80dvh',
            overflowY: 'scroll',
          }}
          size="sm"
          open={!!searchResults}
        >
          {noSearchResults && <MenuItem>No search results</MenuItem>}
          {searchResults && 'users' in searchResults && searchResults.users.length > 0 && (
            <>
              <ListDivider />
              <MenuItem disabled sx={{ fontWeight: 'bold' }}>
                Users
              </MenuItem>
              {searchResults.users.map((user: UserSearchResult) => (
                <MenuItem key={user.id}>
                  <AccountCircleOutlined />
                  {user.name}
                </MenuItem>
              ))}
            </>
          )}
          {searchResults && 'projects' in searchResults && searchResults.projects.length > 0 && (
            <>
              <ListDivider />
              <MenuItem disabled sx={{ fontWeight: 'bold' }}>
                Projects
              </MenuItem>
              {searchResults.projects.map((project: ProjectSearchResult) => (
                <MenuItem
                  key={project.id}
                  onClick={() => {
                    setSearch('');
                    navigate(`/projects/${project.id}`);
                  }}
                >
                  <FolderOutlined />
                  {project.name}
                </MenuItem>
              ))}
            </>
          )}
          {searchResults && 'tasks' in searchResults && searchResults.tasks.length > 0 && (
            <>
              <ListDivider />
              <MenuItem disabled sx={{ fontWeight: 'bold' }}>
                Tasks
              </MenuItem>
              {searchResults.tasks.map((task: TaskSearchResult) => (
                <MenuItem
                  key={task.id}
                  onClick={() => {
                    setSearch('');
                    navigate(`/projects/${task.projectId}/tasks/${task.id}`);
                  }}
                >
                  <AccountTreeOutlined />
                  {task.name}
                </MenuItem>
              ))}
            </>
          )}
          {searchResults && 'events' in searchResults && searchResults.events.length > 0 && (
            <>
              <ListDivider />
              <MenuItem disabled sx={{ fontWeight: 'bold' }}>
                Events
              </MenuItem>
              {searchResults.events.map((event: EventSearchResult) => (
                <MenuItem
                  key={event.id}
                  onClick={() => {
                    setSearch('');
                    navigate(`/projects/${event.projectId}/events/${event.id}`);
                  }}
                >
                  <CalendarMonthOutlined />
                  {event.name}
                </MenuItem>
              ))}
            </>
          )}
          {searchResults && 'dailyLogs' in searchResults && searchResults.dailyLogs.length > 0 && (
            <>
              <ListDivider />
              <MenuItem disabled sx={{ fontWeight: 'bold' }}>
                Daily Logs
              </MenuItem>
              {searchResults.dailyLogs.map((dailyLog: DailyLogSearchResult) => (
                <MenuItem
                  key={dailyLog.id}
                  onClick={() => {
                    setSearch('');
                    navigate(`/projects/${dailyLog.projectId}/dailyLogs/${dailyLog.id}`);
                  }}
                >
                  <CalendarTodayOutlined />
                  {dailyLog.notes}
                </MenuItem>
              ))}
            </>
          )}
          {searchResults && 'contracts' in searchResults && searchResults.contracts.length > 0 && (
            <>
              <ListDivider />
              <MenuItem disabled sx={{ fontWeight: 'bold' }}>
                Contracts
              </MenuItem>
              {searchResults.contracts.map((contract: ContractSearchResult) => (
                <MenuItem
                  key={contract.id}
                  onClick={() => {
                    setSearch('');
                    navigate(`/projects/${contract.projectId}/contracts/${contract.id}`);
                  }}
                >
                  <WorkOutline />
                  {contract.name}
                </MenuItem>
              ))}
            </>
          )}
          {searchResults && 'messages' in searchResults && searchResults.messages.length > 0 && (
            <>
              <ListDivider />
              <MenuItem disabled sx={{ fontWeight: 'bold' }}>
                Messages
              </MenuItem>
              {searchResults.messages.map((message: MessageSearchResult) => (
                <MenuItem
                  key={message.id}
                  onClick={() => {
                    setSearch('');
                    navigate(`/projects/${message.projectId}/chats/${message.chatId}/messages`);
                  }}
                >
                  <MessageOutlined />
                  {message.content}
                </MenuItem>
              ))}
            </>
          )}
        </Menu>
      </Dropdown>
      <Box
        sx={{
          minHeight: 0,
          overflow: 'hidden auto',
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
          [`& .${listItemButtonClasses.root}`]: {
            gap: 1.5,
          },
        }}
      >
        <List
          size="sm"
          sx={{
            gap: 1,
            '--List-nestedInsetStart': '30px',
            '--ListItem-radius': (theme) => theme.vars.radius.sm,
          }}
        >
          <ListItem>
            <ListItemButton
              color="primary"
              onClick={() => navigate('/')}
              selected={!!matchPath('/', location.pathname)}
            >
              <ListItemDecorator>
                <HomeRounded />
              </ListItemDecorator>
              <ListItemContent>Dashboard</ListItemContent>
            </ListItemButton>
          </ListItem>

          <ListItem>
            <ListItemButton
              color="primary"
              onClick={() => navigate('/projects')}
              selected={!!matchPath('/projects/*', location.pathname)}
            >
              <ListItemDecorator>
                <FolderRounded />
              </ListItemDecorator>
              <ListItemContent>Projects</ListItemContent>
            </ListItemButton>
          </ListItem>

          <ListItem>
            <ListItemButton
              color="primary"
              onClick={() => navigate('chats')}
              selected={!!matchPath('/chats', location.pathname)}
            >
              <ListItemDecorator>
                <QuestionAnswerRounded />
              </ListItemDecorator>
              <ListItemContent>Chats</ListItemContent>
              <Badge badgeContent={chatsBadge} showZero={false} sx={{ marginRight: 1 }} />
            </ListItemButton>
          </ListItem>
          <ListItem>
            <ListItemButton
              color="primary"
              onClick={() => navigate('calendar')}
              selected={!!matchPath('/calendar', location.pathname)}
            >
              <ListItemDecorator>
                <CalendarMonthRounded />
              </ListItemDecorator>
              <ListItemContent>Calendar</ListItemContent>
            </ListItemButton>
          </ListItem>
        </List>

        <List
          size="sm"
          sx={{
            mt: 'auto',
            flexGrow: 0,
            '--ListItem-radius': (theme) => theme.vars.radius.sm,
            '--List-gap': '8px',
            mb: 2,
          }}
        >
          {process.env['NX_PUBLIC_ENVIRONMENT'] !== 'production' && (
            <ListItem>
              <ListItemButton color="danger">
                <ListItemDecorator>
                  <StorageRounded />
                </ListItemDecorator>
                <ListItemContent>{process.env['NX_PUBLIC_ENVIRONMENT']?.toLocaleUpperCase()}</ListItemContent>
              </ListItemButton>
            </ListItem>
          )}
          <ListItem>
            <ListItemButton
              color="primary"
              onClick={() => navigate('appDownload')}
              selected={!!matchPath('/appDownload', location.pathname)}
            >
              <ListItemDecorator>
                <SmartphoneRounded />
              </ListItemDecorator>
              <ListItemContent>App Download</ListItemContent>
            </ListItemButton>
          </ListItem>
          <ListItem>
            <ListItemButton color="primary" component="a" href="mailto:hello@builderbud.com">
              <ListItemDecorator>
                <SupportRounded />
              </ListItemDecorator>
              <ListItemContent>Support</ListItemContent>
            </ListItemButton>
          </ListItem>
          <ListItem>
            <ListItemButton
              color="primary"
              onClick={() => navigate('settings')}
              selected={!!matchPath('/settings', location.pathname)}
            >
              <ListItemDecorator>
                <SettingsRounded />
              </ListItemDecorator>
              <ListItemContent>Settings</ListItemContent>
              <Badge badgeContent={invitationsBadge} showZero={false} sx={{ marginRight: 1 }} />
            </ListItemButton>
          </ListItem>
        </List>
      </Box>
      <Divider />
      <Stack direction="row" justifyContent={'space-between'}>
        <Stack alignItems="center" flexGrow={2} onClick={() => navigate('settings/profile')}>
          <AvatarComponent user={me ?? undefined} size="lg" />
          <Typography level="body-xs">{`${firstName} ${lastName}`}</Typography>
          <Typography level="body-xs">{email}</Typography>
        </Stack>
        <IconButton
          size="sm"
          variant="plain"
          color="neutral"
          onClick={async () => {
            if (fcmToken) {
              await deleteDeviceToken({ token: fcmToken });
            }

            signOut(auth).then(() => {
              console.log('User signed out!');
              //Wait for the screens to unmount before resetting the api state otherwise RTK will try to refetch data automatically
              setTimeout(() => {
                dispatch(api.util.resetApiState());
              }, 250);
            });
          }}
        >
          <LogoutRounded />
        </IconButton>
      </Stack>
    </Sheet>
  );
}
