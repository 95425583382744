/* eslint-disable jsx-a11y/anchor-is-valid */
import { ArrowDropDown, MoreHorizRounded, Photo, WarningRounded } from '@mui/icons-material';
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Dropdown,
  FormControl,
  FormLabel,
  IconButton,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  Modal,
  ModalDialog,
  Option,
  Select,
  Stack,
  Typography,
} from '@mui/joy';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Project, ProjectStatus, UserProjectRole, canUserDeleteProject } from '@builder-bud/common';
import {
  AlertObject,
  PROJECT_STATUS_OPTIONS,
  selectCurrentUserId,
  selectIsHomeownerAppRole,
  showErrorAlert,
  showInfoAlert,
  useAppDispatch,
  useAppSelector,
  useDeleteProjectMutation,
} from '@builder-bud/common-ui';

import CircularProgressComponent from '../../components/circular-progress.component';
import DateComponent from '../../components/date.component';
import MembersComponent from '../../components/members.component';
import ProjectStatusComponent from '../../components/project-status.component';
import { Order, getComparator } from '../../components/table';
import TableComponent from '../../components/table.component';

export default function ProjectTableComponent({ rows }: { rows: Project[] }) {
  const dispatch = useAppDispatch();
  const [deleteProject] = useDeleteProjectMutation();

  const isHomeowner = useAppSelector(selectIsHomeownerAppRole);
  const currentUserId = useAppSelector(selectCurrentUserId);

  const [order, setOrder] = useState<Order>('');
  const [statusFilter, setStatusFilter] = useState<string[]>([
    ProjectStatus.NotStarted,
    ProjectStatus.InProgress,
    ProjectStatus.Paused,
  ]);
  const [modalOpen, setModalOpen] = useState(false);
  const [rowToDelete, setRowToDelete] = useState<Project | null>(null);
  const [isDeleting, setIsDeleting] = useState(false);

  const filteredRows = statusFilter.length ? rows.filter((row) => statusFilter.includes(row.status)) : rows;

  async function handleDeleteProject() {
    if (rowToDelete) {
      setIsDeleting(true);
      try {
        await deleteProject({ id: rowToDelete.id }).unwrap();
        dispatch(showInfoAlert('Successfully deleted Project'));
      } catch (error) {
        console.log(error);
        dispatch(showErrorAlert(error as AlertObject));
      } finally {
        setRowToDelete(null);
        setIsDeleting(false);
      }
    }
  }

  const renderFilters = () => (
    <FormControl size="sm">
      <FormLabel>Status</FormLabel>
      <Select
        value={statusFilter}
        onChange={(e, newValue) => setStatusFilter(newValue)}
        size="sm"
        placeholder="Filter by status"
        slotProps={{ button: { sx: { whiteSpace: 'nowrap' } } }}
        multiple={true}
      >
        {PROJECT_STATUS_OPTIONS.map((option, i) => (
          <Option key={option.value} value={option.value}>
            {option.label}
          </Option>
        ))}
      </Select>
    </FormControl>
  );

  const renderTableHead = () => (
    <thead>
      <tr>
        <th style={{ padding: '12px 6px' }}>
          <Link
            underline="none"
            color="primary"
            component="button"
            onClick={() => setOrder(order === '' ? 'asc' : order === 'asc' ? 'desc' : '')}
            fontWeight="lg"
            endDecorator={<ArrowDropDown />}
            sx={{
              '& svg': {
                transition: '0.2s',
                transform: order === '' ? 'rotate(90deg)' : order === 'desc' ? 'rotate(0deg)' : 'rotate(180deg)',
              },
            }}
          >
            Project
          </Link>
        </th>
        <th style={{ padding: '12px 6px' }}>Status</th>
        <th style={{ padding: '12px 6px' }}>{isHomeowner ? 'Contractor' : 'Homeowner'}</th>
        <th style={{ padding: '12px 6px' }}>Address</th>
        <th style={{ padding: '12px 6px' }}>Timeline</th>
        <th style={{ width: 48, padding: '12px 6px' }}></th>
      </tr>
    </thead>
  );

  function TableRowMenu({ row }: { row: Project }) {
    const navigate = useNavigate();

    return (
      <Dropdown>
        <MenuButton
          slots={{ root: IconButton }}
          slotProps={{ root: { variant: 'plain', color: 'neutral', size: 'sm' } }}
        >
          <MoreHorizRounded />
        </MenuButton>
        <Menu size="sm" sx={{ minWidth: 140 }}>
          <MenuItem onClick={() => navigate(`/projects/${row.id}`)}>View</MenuItem>
          {currentUserId && canUserDeleteProject(row, currentUserId) && (
            <MenuItem
              color="danger"
              onClick={() => {
                setRowToDelete(row);
                setModalOpen(true);
              }}
            >
              Delete
            </MenuItem>
          )}
        </Menu>
      </Dropdown>
    );
  }

  function TableRow({ row }: { row: Project }) {
    const navigate = useNavigate();

    return (
      <tr key={row.id} style={{ backgroundColor: 'var(--joy-palette-common-white)' }}>
        <td>
          <Stack flexDirection='row'>
            {row.coverImageThumbnailUrl ?
              <img
                src={row.coverImageThumbnailUrl}
                alt=""
                height={32}
                width={32}
                style={{ marginRight: 8 }}
                onError={(e) => {
                  if (e.target && row.coverImageUrl) {
                    (e.target as HTMLImageElement).src = row.coverImageUrl;
                  }
                }}
              />
              :
              <Photo style={{ fontSize: 32, marginRight: 8 }} />
            }
            <Link onClick={() => navigate(`/projects/${row.id}`)}>
              <Typography level="body-xs">{row.name}</Typography>
            </Link>
          </Stack>
        </td>
        <td>
          <ProjectStatusComponent project={row} />
        </td>
        <td>
          <MembersComponent
            userIds={row.userIds}
            projectId={row.id}
            projectRole={isHomeowner ? UserProjectRole.Contractor : UserProjectRole.Homeowner}
          />
        </td>
        <td>
          <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
            <div>
              <Typography level="body-xs">
                {row.addressLine1} {row.addressLine2}
              </Typography>
              <Typography level="body-xs">{`${row.city}, ${row.stateCode} ${row.zipCode}`}</Typography>
            </div>
          </Box>
        </td>
        <td>
          <Stack flexDirection="row">
            <DateComponent date={row.startDate} />
            {' - '}
            <DateComponent date={row.startDate} />
          </Stack>
        </td>
        <td>
          <TableRowMenu row={row} />
        </td>
      </tr>
    );
  }

  if (isDeleting) return <CircularProgressComponent />;

  return (
    <>
      <TableComponent
        renderFilters={renderFilters}
        renderTableHead={renderTableHead}
        TableRow={TableRow}
        rows={filteredRows}
        comparator={getComparator(order, 'name')}
      />
      <Modal
        open={modalOpen}
        onClose={() => {
          setRowToDelete(null);
          setModalOpen(false);
        }}
      >
        <ModalDialog variant="outlined" role="alertdialog">
          <DialogTitle>
            <WarningRounded />
            Confirmation
          </DialogTitle>
          <Divider />
          <DialogContent>Are you sure you want to delete this project?</DialogContent>
          <DialogActions>
            <Button variant="solid" color="danger" onClick={() => handleDeleteProject()}>
              Delete project
            </Button>
            <Button variant="plain" color="neutral" onClick={() => setModalOpen(false)}>
              Cancel
            </Button>
          </DialogActions>
        </ModalDialog>
      </Modal>
    </>
  );
}
