import { Add, ChevronRightRounded, HomeRounded } from '@mui/icons-material';
import {
  Box,
  Breadcrumbs,
  Dropdown,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  Tab,
  TabList,
  TabPanel,
  Tabs,
  Typography,
  tabClasses,
} from '@mui/joy';
import { matchPath, useLocation, useNavigate } from 'react-router-dom';

import { EventId, EventWithFiles, ProjectId } from '@builder-bud/common';
import {
  getUserOptions,
  isActiveProject,
  openModal,
  useAppDispatch,
  useEditEventMutation,
  useGetEventQuery,
  useGetProjectQuery,
  useGetUsersQuery,
} from '@builder-bud/common-ui';

import CircularProgressComponent from '../../components/circular-progress.component';
import ErrorLoadingComponent from '../../components/error-loading.component';
import NotFoundComponent from '../../components/not-found.component';
import { useRequiredParams } from '../../utils';
import FilesTableComponent from '../files/files-table.component';
import FileUploadModal from '../files/project-file-upload.modal';
import EventForm from './event.form';

export default function EventDetailsScreen() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const { projectId, eventId } = useRequiredParams<{ projectId: ProjectId; eventId: EventId }>();
  const [updateEvent] = useEditEventMutation();

  const modalContent = <FileUploadModal projectId={projectId} eventId={eventId} />;

  const { data: project } = useGetProjectQuery(projectId);

  const {
    data: event,
    isLoading: isEventLoading,
    isFetching: isEventFetching,
    isError: isEventError,
    error: eventError = {},
  } = useGetEventQuery({ projectId, eventId });

  const {
    data: users = [],
    isLoading: isUsersLoading,
    isFetching: isUsersFetching,
    isError: isUsersError,
    error: usersError = {},
  } = useGetUsersQuery();

  const isLoading = isEventLoading || isUsersLoading;
  const isFetching = isEventFetching || isUsersFetching;
  const isError = isEventError || isUsersError;
  const error = isEventError ? eventError : isUsersError ? usersError : {};

  if (isLoading || isFetching) return <CircularProgressComponent />;
  if (isError) return <ErrorLoadingComponent error={error} />;
  if (!event) return <NotFoundComponent />;

  const getDefaultTabValue = () => {
    return matchPath('projects/:projectId/events/:eventId/files', location.pathname) ? 1 : 0;
  };

  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Breadcrumbs
          size="sm"
          aria-label="breadcrumbs"
          separator={<ChevronRightRounded fontSize="small" />}
          sx={{ pl: 0 }}
        >
          <Link underline="none" color="neutral" href="/" aria-label="Home">
            <HomeRounded />
          </Link>
          <Link underline="hover" color="neutral" href="/projects" fontSize={12} fontWeight={500}>
            Projects
          </Link>
          <Link underline="hover" color="neutral" href={`/projects/${event.projectId}`} fontSize={12} fontWeight={500}>
            {event.projectId}
          </Link>
          <Link
            underline="hover"
            color="neutral"
            href={`/projects/${event.projectId}/events`}
            fontSize={12}
            fontWeight={500}
          >
            Events
          </Link>
          <Typography color="primary" fontWeight={500} fontSize={12}>
            {event.id}
          </Typography>
        </Breadcrumbs>
      </Box>
      <Box
        sx={{
          display: 'flex',
          mb: 1,
          gap: 1,
          flexDirection: { xs: 'column', sm: 'row' },
          alignItems: { xs: 'start', sm: 'center' },
          flexWrap: 'wrap',
          justifyContent: 'space-between',
        }}
      >
        <Typography level="h2" component="h1">
          {event.name}
        </Typography>
        {isActiveProject(project) && (
          <Dropdown>
            <MenuButton color="secondary" startDecorator={<Add />}>
              Add to Event
            </MenuButton>
            <Menu>
              <MenuItem
                color="secondary"
                onClick={() => {
                  dispatch(openModal(modalContent));
                }}
              >
                Add File
              </MenuItem>
            </Menu>
          </Dropdown>
        )}
      </Box>

      <Tabs
        defaultValue={0}
        value={getDefaultTabValue()}
        onChange={(e, selectedTab) => {
          selectedTab === 0 && navigate(`/projects/${projectId}/events/${event.id}`, { replace: true });
          selectedTab === 1 && navigate(`/projects/${projectId}/events/${event.id}/files`, { replace: true });
        }}
        sx={{
          bgcolor: 'transparent',
        }}
      >
        <TabList
          tabFlex={1}
          size="sm"
          sx={{
            pl: { xs: 0, md: 4 },
            justifyContent: 'left',
            [`&& .${tabClasses.root}`]: {
              fontWeight: '600',
              flex: 'initial',
              color: 'text.tertiary',
              [`&.${tabClasses.selected}`]: {
                bgcolor: 'transparent',
                color: 'text.primary',
                '&::after': {
                  height: '2px',
                  bgcolor: 'primary.500',
                },
              },
            },
          }}
        >
          <Tab sx={{ borderRadius: '6px 6px 0 0' }} indicatorInset value={0}>
            Details
          </Tab>
          <Tab sx={{ borderRadius: '6px 6px 0 0' }} indicatorInset value={1}>
            Files
          </Tab>
        </TabList>

        <TabPanel value={0}>
          <EventForm event={event} saveEvent={updateEvent} users={getUserOptions(users, event.projectId)} />
        </TabPanel>
        <TabPanel value={1}>
          <FilesTableComponent rows={(event as EventWithFiles).files} />
        </TabPanel>
      </Tabs>
    </>
  );
}
