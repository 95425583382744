import { Add, ChevronRightRounded, Close, FilterAlt, HomeRounded, Search } from '@mui/icons-material';
import {
  Box,
  Breadcrumbs,
  Button,
  Divider,
  FormControl,
  FormLabel,
  IconButton,
  Input,
  Link,
  Modal,
  ModalClose,
  ModalDialog,
  Option,
  Select,
  Sheet,
  Stack,
  ToggleButtonGroup,
  Tooltip,
  Typography,
} from '@mui/joy';
import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { TaskStatus, canUserCreateProject, dateToDateWithoutTime, getDateRangeFromToday } from '@builder-bud/common';
import {
  selectIsContractorAppRole,
  selectIsSubcontractorAppRole,
  useAppSelector,
  useGetMeDailyLogsQuery,
  useGetMeEventsQuery,
  useGetMeTasksQuery,
  useGetProjectsQuery,
} from '@builder-bud/common-ui';

import CircularProgressComponent from '../../components/circular-progress.component';
import ErrorLoadingComponent from '../../components/error-loading.component';
import NoProjectsComponent from '../../components/no-projects.component';
import DailyLogsTableComponent from '../daily-logs/daily-logs-table.component';
import EventTableComponent from '../events/events-table.component';
import PunchlistTableComponent from '../tasks/punchlists/punchlist-table.component';
import TaskTableComponent from '../tasks/tasks-table.component';

function DashboardScreen() {
  const navigate = useNavigate();

  const me = useAppSelector((state) => state.auth.me);
  const isContractor = useAppSelector(selectIsContractorAppRole);
  const isSubcontractor = useAppSelector(selectIsSubcontractorAppRole);

  const [open, setOpen] = useState(false);
  const [projectFilter, setProjectFilter] = useState<string[]>([]);
  const [search, setSearch] = useState('');
  const [day, setDay] = useState('today');

  const todaysDate = dateToDateWithoutTime(new Date());
  const { startDate: yesterdaysDate, endDate: tomorrowsDate } = getDateRangeFromToday(1);

  const {
    data: projects = [],
    isLoading: isProjectsLoading,
    isFetching: isProjectsFetching,
    isError: isProjectsError,
    error: projectsError,
  } = useGetProjectsQuery();

  const {
    data: tasks = [],
    isLoading: isTasksLoading,
    isFetching: isTasksFetching,
    isError: isTasksError,
    error: tasksError,
  } = useGetMeTasksQuery({ startDate: yesterdaysDate, endDate: tomorrowsDate });

  const {
    data: events = [],
    isLoading: isEventsLoading,
    isFetching: isEventsFetching,
    isError: isEventsError,
    error: eventsError,
  } = useGetMeEventsQuery({ startDate: yesterdaysDate, endDate: tomorrowsDate });

  const {
    data: dailyLogs = [],
    isLoading: isDailyLogsLoading,
    isFetching: isDailyLogsFetching,
    isError: isDailyLogsError,
    error: dailyLogsError,
  } = useGetMeDailyLogsQuery({ startDate: yesterdaysDate, endDate: tomorrowsDate });

  const filteredProjects = projectFilter ? projects.filter((project) => projectFilter.includes(project.id)) : projects;

  const yesterdaysTasks = useMemo(() => {
    return tasks
      .filter((task) => !task.parentTaskId)
      .filter((task) => task.status !== TaskStatus.Archived)
      .filter((task) => search === '' || task.name.toLowerCase().includes(search.toLowerCase()))
      .filter((task) => filteredProjects.find((project) => project.id === task.projectId))
      .filter((task) => (task.dueDate ? dateToDateWithoutTime(task.dueDate) === yesterdaysDate : false));
  }, [filteredProjects, search, tasks, yesterdaysDate]);

  const todaysTasks = useMemo(() => {
    return tasks
      .filter((task) => !task.parentTaskId)
      .filter((task) => task.status !== TaskStatus.Archived)
      .filter((task) => search === '' || task.name.toLowerCase().includes(search.toLowerCase()))
      .filter((task) => filteredProjects.find((project) => project.id === task.projectId))
      .filter((task) => (task.dueDate ? dateToDateWithoutTime(task.dueDate) === todaysDate : false));
  }, [filteredProjects, search, tasks, todaysDate]);

  const tomorrowsTasks = useMemo(() => {
    return tasks
      .filter((task) => !task.parentTaskId)
      .filter((task) => task.status !== TaskStatus.Archived)
      .filter((task) => search === '' || task.name.toLowerCase().includes(search.toLowerCase()))
      .filter((task) => filteredProjects.find((project) => project.id === task.projectId))
      .filter((task) => (task.dueDate ? dateToDateWithoutTime(task.dueDate) === tomorrowsDate : false));
  }, [filteredProjects, search, tasks, tomorrowsDate]);

  const yesterdaysEvents = useMemo(() => {
    return events
      .filter((event) => filteredProjects.find((project) => project.id === event.projectId))
      .filter((event) => search === '' || event.name.toLowerCase().includes(search.toLowerCase()))
      .filter((event) => event.startDate <= yesterdaysDate && yesterdaysDate <= event.endDate);
  }, [filteredProjects, search, events, yesterdaysDate]);

  const todaysEvents = useMemo(() => {
    return events
      .filter((event) => filteredProjects.find((project) => project.id === event.projectId))
      .filter((event) => search === '' || event.name.toLowerCase().includes(search.toLowerCase()))
      .filter((event) => event.startDate <= todaysDate && todaysDate <= event.endDate);
  }, [filteredProjects, search, events, todaysDate]);

  const tomorrowsEvents = useMemo(() => {
    return events
      .filter((event) => filteredProjects.find((project) => project.id === event.projectId))
      .filter((event) => search === '' || event.name.toLowerCase().includes(search.toLowerCase()))
      .filter((event) => event.startDate <= tomorrowsDate && tomorrowsDate <= event.endDate);
  }, [filteredProjects, search, events, tomorrowsDate]);

  const yesterdaysDailyLogs = useMemo(() => {
    return dailyLogs
      .filter((log) => filteredProjects.find((project) => project.id === log.projectId))
      .filter((log) => search === '' || log.notes.toLowerCase().includes(search.toLowerCase()))
      .filter((log) => dateToDateWithoutTime(log.createdAt) === yesterdaysDate);
  }, [filteredProjects, search, dailyLogs, yesterdaysDate]);

  const todaysDailyLogs = useMemo(() => {
    return dailyLogs
      .filter((log) => filteredProjects.find((project) => project.id === log.projectId))
      .filter((log) => search === '' || log.notes.toLowerCase().includes(search.toLowerCase()))
      .filter((log) => dateToDateWithoutTime(log.createdAt) === todaysDate);
  }, [filteredProjects, search, dailyLogs, todaysDate]);

  const tomorrowsDailyLogs = useMemo(() => {
    return dailyLogs
      .filter((log) => filteredProjects.find((project) => project.id === log.projectId))
      .filter((log) => search === '' || log.notes.toLowerCase().includes(search.toLowerCase()))
      .filter((log) => dateToDateWithoutTime(log.createdAt) === tomorrowsDate);
  }, [filteredProjects, search, dailyLogs, tomorrowsDate]);

  const incompletePunchlists = useMemo(() => {
    return tasks
      .filter((task) => task.parentTaskId)
      .filter((task) => task.status !== TaskStatus.Completed)
      .filter((task) => search === '' || task.name.toLowerCase().includes(search.toLowerCase()))
      .filter((task) => filteredProjects.find((project) => project.id === task.projectId));
  }, [filteredProjects, search, tasks]);

  const isLoading = isProjectsLoading || isTasksLoading || isEventsLoading || isDailyLogsLoading;
  const isFetching = isProjectsFetching || isTasksFetching || isEventsFetching || isDailyLogsFetching;
  const isError = isProjectsError || isTasksError || isEventsError || isDailyLogsError;
  const error = isProjectsError
    ? projectsError
    : isTasksError
    ? tasksError
    : isEventsError
    ? eventsError
    : isDailyLogsError
    ? dailyLogsError
    : {};

  if (isLoading || isFetching) return <CircularProgressComponent />;
  if (isError) return <ErrorLoadingComponent error={error} />;

  const isAllowedCreateProject = me && canUserCreateProject(me, projects.length);

  const tasksData = day === 'yesterday' ? yesterdaysTasks : day === 'today' ? todaysTasks : tomorrowsTasks;
  const eventsData = day === 'yesterday' ? yesterdaysEvents : day === 'today' ? todaysEvents : tomorrowsEvents;
  const dailyLogsData =
    day === 'yesterday' ? yesterdaysDailyLogs : day === 'today' ? todaysDailyLogs : tomorrowsDailyLogs;

  const renderFilters = () => (
    <FormControl size="sm">
      <FormLabel>Projects</FormLabel>
      <Select
        value={projectFilter}
        onChange={(e, newValue) => setProjectFilter(newValue)}
        size="sm"
        placeholder="Filter by project"
        slotProps={{ button: { sx: { whiteSpace: 'nowrap' } } }}
        multiple={true}
      >
        {projects.map((option) => (
          <Option key={option.id} value={option.id}>
            {option.name}
          </Option>
        ))}
      </Select>
    </FormControl>
  );

  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Breadcrumbs
          size="sm"
          aria-label="breadcrumbs"
          separator={<ChevronRightRounded fontSize="small" />}
          sx={{ pl: 0 }}
        >
          <Link underline="none" color="neutral" href="/" aria-label="Home">
            <HomeRounded />
          </Link>
          <Typography color="primary" fontWeight={500} fontSize={12}>
            Dashboard
          </Typography>
        </Breadcrumbs>
      </Box>
      <Box
        sx={{
          display: 'flex',
          mb: 1,
          gap: 1,
          flexDirection: { xs: 'column', sm: 'row' },
          alignItems: { xs: 'start', sm: 'center' },
          flexWrap: 'wrap',
          justifyContent: 'space-between',
        }}
      >
        <Typography level="h2" component="h1">
          Dashboard
        </Typography>

        <Tooltip
          title={
            isAllowedCreateProject
              ? ''
              : 'You have used the max projects for your current subscription. Please go to Settings > Subscriptions Management'
          }
          variant="solid"
          placement="top"
          arrow
          onClick={() => {
            navigate('settings/subscriptions');
          }}
          sx={{ zIndex: 20002 }}
        >
          <Button
            color="secondary"
            variant="solid"
            startDecorator={<Add />}
            onClick={() => navigate('/projects/new')}
            disabled={!isAllowedCreateProject}
          >
            Add project
          </Button>
        </Tooltip>
      </Box>

      <Sheet
        sx={{
          display: { xs: 'flex', sm: 'none' },
          my: 1,
          gap: 1,
        }}
      >
        <Input
          size="sm"
          placeholder="Filter by name"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          startDecorator={<Search />}
          endDecorator={search !== '' ? <Close onClick={() => setSearch('')} /> : null}
          sx={{ flexGrow: 1 }}
        />
        <IconButton size="sm" variant="outlined" color="neutral" onClick={() => setOpen(true)}>
          <FilterAlt />
        </IconButton>
        <Modal open={open} onClose={() => setOpen(false)}>
          <ModalDialog aria-labelledby="filter-modal" layout="fullscreen">
            <ModalClose />
            <Typography id="filter-modal" level="h2">
              Filters
            </Typography>
            <Divider sx={{ my: 2 }} />
            <Sheet sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
              {renderFilters()}
              <Button color="primary" onClick={() => setOpen(false)}>
                Submit
              </Button>
            </Sheet>
          </ModalDialog>
        </Modal>
      </Sheet>

      <Box
        sx={{
          borderRadius: 'sm',
          py: 2,
          display: { xs: 'none', sm: 'flex' },
          flexWrap: 'wrap',
          gap: 1.5,
          '& > *': {
            minWidth: { xs: '120px', md: '160px' },
          },
        }}
      >
        <FormControl sx={{ flex: 1 }} size="sm">
          <FormLabel>Name</FormLabel>
          <Input
            size="sm"
            placeholder="Filter by name"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            startDecorator={<Search />}
            endDecorator={search !== '' ? <Close onClick={() => setSearch('')} /> : null}
          />
        </FormControl>
        {renderFilters()}
      </Box>

      {projects.length === 0 ? (
        <NoProjectsComponent />
      ) : (
        <>
          <Stack sx={{ alignContent: 'center', rowGap: 2 }}>
            <Typography level="title-lg">Hi {me?.firstName}, your day at a glance:</Typography>

            <ToggleButtonGroup
              color="secondary"
              variant="outlined"
              buttonFlex={1}
              value={day}
              onChange={(event, day) => {
                setDay(day ? day : 'today');
              }}
            >
              <Button value="yesterday">Yesterday</Button>
              <Button value="today">Today</Button>
              <Button value="tomorrow">Tomorrow</Button>
              {(isContractor || isSubcontractor) && <Button value="punchlists">Punchlists</Button>}
            </ToggleButtonGroup>
          </Stack>

          {day !== 'punchlists' ? (
            <Stack sx={{ rowGap: 3 }}>
              {tasksData.length === 0 &&
                eventsData.length === 0 &&
                dailyLogsData.length === 0 &&
                (day === 'yesterday' ? (
                  <Typography level="title-lg">No tasks due, events scheduled or daily logs for yesterday</Typography>
                ) : day === 'today' ? (
                  <Typography level="title-lg">No tasks due, events scheduled or daily logs for today</Typography>
                ) : (
                  <Typography level="title-lg">No tasks due, events scheduled or daily logs for tomorrow</Typography>
                ))}

              {tasksData.length > 0 && (
                <Stack sx={{ rowGap: 1 }}>
                  <Typography level="title-lg">Tasks</Typography>
                  <TaskTableComponent rows={tasksData} showProject={true} hideSearch={true} />
                </Stack>
              )}
              {eventsData.length > 0 && (
                <Stack sx={{ rowGap: 1 }}>
                  <Typography level="title-lg">Events</Typography>
                  <EventTableComponent rows={eventsData} showProject={true} hideSearch={true} />
                </Stack>
              )}
              {dailyLogsData.length > 0 && (
                <Stack sx={{ rowGap: 1 }}>
                  <Typography level="title-lg">Daily Logs</Typography>
                  <DailyLogsTableComponent
                    rows={dailyLogsData}
                    showProject={true}
                    hideSearch={true}
                    showPhotos={true}
                  />
                </Stack>
              )}
            </Stack>
          ) : (
            <Stack sx={{ rowGap: 3 }}>
              {incompletePunchlists.length === 0 && <Typography level="title-lg">No punchlist items</Typography>}
              {incompletePunchlists.length > 0 && (
                <Stack sx={{ rowGap: 1 }}>
                  <PunchlistTableComponent rows={incompletePunchlists} showProject={true} hideSearch={true} />
                </Stack>
              )}
            </Stack>
          )}
        </>
      )}
    </>
  );
}

export default DashboardScreen;
