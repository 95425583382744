import { Box, Card, Typography } from '@mui/joy';

function ErrorLoadingComponent({ error }: { error: any }) {
  return (
    <Box sx={styles.container}>
      <Card sx={styles.card}>
        <Typography level="h3">An unexpected error has occurred</Typography>
        <Typography>{error.status}</Typography>
        <Typography>{error.error}</Typography>
        <img src="../../assets/line-paint-cross.png" style={styles.image} alt="" />
      </Card>
    </Box>
  );
}

export default ErrorLoadingComponent;

const styles = {
  container: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  card: {
    maxWidth: 600,
    maxHeight: 600,
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  image: {
    height: 320,
  },
};
