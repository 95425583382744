import { ChevronRightRounded, HomeRounded } from '@mui/icons-material';
import { Box, Breadcrumbs, Link, Typography } from '@mui/joy';

import { ContractStatus, ProjectId } from '@builder-bud/common';
import { useCreateContractMutation } from '@builder-bud/common-ui';

import { useRequiredParams } from '../../utils';
import ContractForm from './contract.form';

export default function ContractCreateScreen() {
  const { projectId } = useRequiredParams<{ projectId: ProjectId }>();
  const [createContract] = useCreateContractMutation();

  const contract = {
    projectId,
    status: ContractStatus.Draft,
  };

  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Breadcrumbs
          size="sm"
          aria-label="breadcrumbs"
          separator={<ChevronRightRounded fontSize="small" />}
          sx={{ pl: 0 }}
        >
          <Link underline="none" color="neutral" href="/" aria-label="Home">
            <HomeRounded />
          </Link>
          <Link underline="hover" color="neutral" href="/projects" fontSize={12} fontWeight={500}>
            Projects
          </Link>
          <Link
            underline="hover"
            color="neutral"
            href={`/projects/${contract.projectId}`}
            fontSize={12}
            fontWeight={500}
          >
            {contract.projectId}
          </Link>
          <Link
            underline="hover"
            color="neutral"
            href={`/projects/${contract.projectId}/contracts`}
            fontSize={12}
            fontWeight={500}
          >
            Work order
          </Link>
          <Typography color="primary" fontWeight={500} fontSize={12}>
            New Work order
          </Typography>
        </Breadcrumbs>
      </Box>
      <Box
        sx={{
          display: 'flex',
          mb: 1,
          gap: 1,
          flexDirection: { xs: 'column', sm: 'row' },
          alignItems: { xs: 'start', sm: 'center' },
          flexWrap: 'wrap',
          justifyContent: 'space-between',
        }}
      >
        <Typography level="h2" component="h1">
          New work order
        </Typography>
        <Typography level="body-sm" color="warning">
          Save draft first to add line items or files
        </Typography>
      </Box>

      <ContractForm contract={contract} files={[]} saveContract={createContract} />
    </>
  );
}
